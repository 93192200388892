import { combineReducers } from "redux";
import nftReducer from "./nfts";
import hotCollectionsReducer from "./hotCollections";
import authorListReducer from "./authorList";
import filterReducer from "./filters";
import blogPostsReducer from "./blogs";
import auth from "./auth";
import NFT from "./NFT";
import NftFilters from "./NftFilters";
import ContactUs from "./contactUs";

export const rootReducer = combineReducers({
  NFT: nftReducer,
  hotCollection: hotCollectionsReducer,
  authors: authorListReducer,
  filters: filterReducer,
  blogs: blogPostsReducer,
  auth: auth,
  NFTData: NFT,
  NftFilters: NftFilters,
  ContactUs: ContactUs,
});

const reducers = (state, action) => rootReducer(state, action);

export default reducers;
