import {  Drawer } from "antd";
import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import XummWalletModal from "../Pages/Auth/XummWalletModal";
import logo from "./../../assets/Images/Nexible.png";
const NavDrawer = ({
  handleLogout,
  signedInUserState,
  xummBalance,
  show,
  setShow,
  handleClose,
  handleShow,
  walletAccount,
  connectButonHide,
}) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const navigation = useNavigate();

  return (
    <>
      <button className="nav-icon  forMob" onClick={showDrawer}>
        <div className="menu-line white"></div>
        <div className="menu-line1 white"></div>
        <div className="menu-line2 white"></div>
      </button>
      <Drawer
        className="navbarDrawer"
        placement="left"
        onClose={onClose}
        visible={visible}
      >
        <div className="logo px-0">
          <div className="navbar-title navbar-item">
            <div
              onClick={() => {
                handleClose();
              }}
            >
              {/* <img
                  src="/img/logo.png"
                  className="img-fluid d-block"
                  alt="#"
                />
                <img
                  src="/img/logo-2.png"
                  className="img-fluid d-3"
                  alt="#"
                />
                <img
                  src="/img/logo-3.png"
                  className="img-fluid d-4"
                  alt="#"
                />
                <img
                  src="/img/logo-light.png"
                  className="img-fluid d-none"
                  alt="#"
                /> */}
              <h2 style={{ fontWeight: "500" }}>
                <img className="logoNavbar" src={logo} />
              </h2>
            </div>
          </div>
        </div>
        <div>
          <div className="navbar-item">
            <div
              onClick={() => {
                navigation("/home");

                onClose();
              }}
            >
              Home
            </div>
          </div>
          <div className="navbar-item">
            <div
              onClick={() => {
                navigation("/Explore");

                onClose();
              }}
            >
              Explore
            </div>
          </div>
          <div className="navbar-item">
            <div
              onClick={() => {
                navigation("/Blogs");

                onClose();
              }}
            >
              Blogs
            </div>
          </div>
          <div className="navbar-item">
            {!connectButonHide ? (
              <div
                onClick={() => {
                  navigation("/CreateOptions");
                  onClose();
                }}
              >
                Create
              </div>
            ) : (
              <div
                onClick={() => {
                  handleShow();
                  onClose();
                }}
              >
                Create
              </div>
            )}
          </div>
          <div className="navbar-item">
            <div
              onClick={() => {
                navigation("/wallet");
                onClose();
              }}
            >
              Wallet
            </div>
          </div>
          <div className="navbar-item">
            <div
              onClick={() => {
                navigation("/add-Info");
                onClose();
              }}
            >
              Pricing
            </div>
          </div>
          {!signedInUserState && (
            <div className="navbar-item">
              <div
                onClick={() => {
                  navigation("/Login");
                  onClose();
                }}
              >
                Login
              </div>
            </div>
          )}

          {/* <div className="navbar-item">
            <div
              onClick={() => {
                navigation("/HelpCenter");
                onClose();
              }}
            >
              Help Center
            </div>
          </div> */}
        </div>
        {signedInUserState ? (
          <div className="profileSection">
            <div className="avDetail">
              <img src={signedInUserState.profile} />
              <div>
                <p>
                  <b>Name:</b>
                  {signedInUserState.first_name} {signedInUserState.last_name}
                </p>
                <p>
                  <b>Balance:</b>
                  {xummBalance} XRP
                </p>
                {signedInUserState.account_seeds && (
                  <p>
                    <b>Family seed: </b>
                    {signedInUserState.account_seeds}
                  </p>
                )}
                <p>
                  <b>My Wallet: </b>
                  {signedInUserState.wallet_address}
                </p>
              </div>
            </div>
            <ul className="de-submenu-profile">
              <li>
                <span>
                  <div
                    className="submenu-child"
                    onClick={() => {
                      navigation("/User?u=me");

                      onClose();
                    }}
                  >
                    <i className="fa fa-user"></i> My Collection
                  </div>
                </span>
              </li>
              {/* <li>
                <span>
                  <div
                    className="submenu-child"
                    onClick={() => {
                      navigation("/User?u=me");
                      onClose();
                    }}
                  >
                    <i className="fa fa-user"></i> My profile
                  </div>
                </span>
              </li> */}
              <li>
                <span>
                  <div
                    className="submenu-child"
                    onClick={() => {
                      navigation("/Profile");
                      onClose();
                    }}
                  >
                    <i className="fa fa-pencil"></i> Edit profile
                  </div>
                </span>
              </li>
              {/* <li className="submenu-child" onClick={handleLogout}>
                <span>
                  <i className="fa fa-sign-out"></i> Sign out
                </span>
              </li> */}
            </ul>
          </div>
        ) : null}

        {/* {signedInUserState ?
                    null
                    : */}
        <XummWalletModal
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          handleShow={handleShow}
          connectButonHide={connectButonHide}
          navButton={true}
        />
        {/* } */}
      </Drawer>
    </>
  );
};

export default NavDrawer;
