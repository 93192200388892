import axios from "axios";
import { BASE_URL } from "../../../constant";

export const CONTACT_FORM_LEADER = "CONTACT_FORM_LEADER";
export const contactFormLoader = (payload) => {
  return {
    type: CONTACT_FORM_LEADER,
    payload: payload,
  };
};

export const postContactForm = (data, clearState) => {
  return async (dispatch) => {
    dispatch(contactFormLoader(true));
    var config = {
      method: "post",
      url: `${BASE_URL}/api/v1/user/feedback/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        dispatch(contactFormLoader(false));
        clearState();
      })
      .catch(function (error) {
        console.log(error);
        dispatch(contactFormLoader(false));
      });
  };
};
