import React, { memo, useCallback } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { categories, status, itemsType } from "./constants/filters";
import {
  filterCategories,
  filterStatus,
  filterItemsType,
  filterNftTitle,
} from "../../store/actions";
import { useState } from "react";
import {
  searchDataFn,
  searchTagsDataFn,
} from "../../store/actions/thunks/filters";

const TopFilterBar = ({ pages, setpages, nfts, setNfts }) => {
  const dispatch = useDispatch();
  const FilteredNftSearch = useSelector((e) => e.NftFilters.FilteredNftSearch);
  const [value, setValue] = useState(FilteredNftSearch || "");
  const handleCategory = useCallback(
    (option) => {
      const { value } = option;
      dispatch(filterCategories({ value, singleSelect: true }));
    },
    [dispatch]
  );

  //   const handleStatus = useCallback(
  //     (option) => {
  //       const { value } = option;
  //       dispatch(filterStatus({ value, singleSelect: true }));
  //     },
  //     [dispatch]
  //   );

  // const handleItemsType = useCallback(
  //   (option) => {
  //     const { value } = option;
  //     dispatch(filterItemsType({ value, singleSelect: true }));
  //   },
  //   [dispatch]
  // );

  // const filterNftTitles = useCallback(
  //   (event) => {
  //     const value = event.target.value;
  //     dispatch(filterNftTitle(value));
  //   },
  //   [dispatch]
  // );

  // const defaultValue = {
  //   value: null,
  //   label: "Select Filter",
  // };

  const customStyles = {
    option: (base, state) => ({
      ...base,
      background: "#fff",
      color: "#333",
      borderRadius: state.isFocused ? "0" : 0,
      "&:hover": {
        background: "#eee",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    control: (base, state) => ({
      ...base,
      padding: 2,
    }),
  };
  const onChangeTags = (value) => {
    let Search = `tag=${value?.value}`;
    console.log(Search, "Search");
    setNfts([]);
    dispatch(searchTagsDataFn(Search, 0, 16, value));
    setpages(0);
  };
  const onChangeNftType = (value) => {
    console.log(value, "Value");
    let Search = `nftType=${value?.value}`;
    setNfts([]);
    dispatch(searchTagsDataFn(Search, 0, 16, value));
    setpages(0);
  };
  const onSearch = (e) => {
    e.preventDefault();
    if (value?.length) {
      setNfts([]);
      dispatch(searchDataFn(value, 0, 16));
      setpages(0);
    }
  };
  return (
    <div className="items_filter items_content">
      <div>
        <div className="dropdownSelect one">
          <Select
            styles={customStyles}
            menuContainerStyle={{ zIndex: 999 }}
            options={categories}
            onChange={onChangeTags}
          />
        </div>
        <div className="dropdownSelect two">
          <Select
            styles={customStyles}
            options={status}
            onChange={onChangeNftType}
          />
        </div>
      </div>
      <form
        className="row form-dark"
        id="form_quick_search"
        name="form_quick_search"
        onSubmit={onSearch}
      >
        <div className="col">
          <input
            className="form-control"
            id="name_1"
            name="name_1"
            value={value}
            placeholder="search item here..."
            type="text"
            onChange={(e) => setValue(e.target.value)}
          />
          <button id="btn-submit">
            <i className="fa fa-search bg-color-secondary"></i>
          </button>
          <div className="clearfix"></div>
        </div>
      </form>
      {/* <div className="dropdownSelect three">
        <Select
          styles={customStyles}
          options={itemsType}
          onChange={handleItemsType}
        />
      </div> */}
    </div>
  );
};

export default memo(TopFilterBar);
