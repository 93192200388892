import { CONTACT_FORM_LEADER } from "../actions/thunks";

export const defaultState = {
  contactFormLoader: false,
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    case CONTACT_FORM_LEADER:
      // console.log(action)
      return {
        ...state,
        contactFormLoader: action.payload,
      };
    default:
      return state;
  }
};
export default states;
