import React from 'react'
import "./style.scss"

function Heading({ text, bg, comingSoon,customStyle }) {
  return (
    <div className="Heading" style={{...customStyle}}>
      <div className="Borders"><span></span></div>
      <h3 style={bg ? { background: '#f7f4fd' } : {}} className={`HeadingText ${bg ? bg : ''} ${text.length > 15 ? "HeadingTextLarge" : "HeadingTextSmall"}`}>{text} {comingSoon ? <sub>(Coming Soon)</sub>:null}</h3>
    </div>
  )
}

export default Heading