import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../constant";
import { fetchNftDetail } from "./nfts";

export const GET_USER_NFT_DATA_LOADER = "GET_USER_NFT_DATA_LOADER";
export const getUserNFTLoaderData = (payload) => {
  return {
    type: GET_USER_NFT_DATA_LOADER,
    payload: payload,
  };
};

export const getUserNFT = (id, pages, limit) => {
  return (dispatch) => {
    let token = localStorage.getItem("nexibleToken");
    // const userObject = JSON.parse(localStorage.getItem("nexibleUser"));
    // if (token) {
    dispatch(getUserNFTData([]));
    dispatch(getUserNFTLoaderData(true));
    var config = {
      method: "get",
      url: `${BASE_URL}/api/v1/user/xumm-xrpl/get-user-nft-db/${id}?page=${pages}&limit=${limit}`,
      headers: {
        token: token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        dispatch(getUserNFTLoaderData(false));
        dispatch(getUserNFTLength(response?.data?.nftsLength[0]?.total));
        console.log(response.data.nfts);
        dispatch(getUserNFTData(response.data.nfts));
      })
      .catch(function (error) {
        dispatch(getUserNFTLoaderData(false));
        console.log(error);
      });
    // } else {
    //   dispatch(getUserNFTLoaderData(false));
    // }
  };
};

export const getUserNFTOnSell = (userId, pages, limit) => {
  return (dispatch) => {
    let token = localStorage.getItem("nexibleToken");
    // if (token) {
    dispatch(getUserNFTLoaderData(true));
    dispatch(getUserNFTData([]));
    var config = {
      method: "get",
      url: `${BASE_URL}/api/v1/user/xumm-xrpl/get-user-nft-on-sell/${userId}?page=${pages}&limit=${limit}`,
      headers: {
        token: token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log((response.data, "response.data"));
        dispatch(getUserNFTLoaderData(false));
        dispatch(getUserNFTLength(response?.data?.nftsLength[0]?.total));
        console.log(response.data.nfts);
        dispatch(getUserNFTData(response.data.nfts));
      })
      .catch(function (error) {
        dispatch(getUserNFTLoaderData(false));
        console.log(error);
      });
    // } else {
    //   dispatch(getUserNFTLoaderData(false));
    // }
  };
};
export const getUserNFTViewFn = (userId) => {
  return (dispatch) => {
    let token = localStorage.getItem("nexibleToken");
    // const userObject = JSON.parse(localStorage.getItem("nexibleUser"));
    // if (token) {
    dispatch(getUserNFTLoaderData(true));
    var config = {
      method: "get",
      url: `${BASE_URL}/api/v1/user/profile/${userId}`,
      headers: {
        token: token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data, "response.data");
        dispatch(getUserNFTLoaderData(false));
        dispatch(getUserNFTViewData(response?.data?.profile));
      })
      .catch(function (error) {
        dispatch(getUserNFTLoaderData(false));
        console.log(error);
      });
    // } else {
    //   dispatch(getUserNFTLoaderData(false));
    // }
  };
};

export const GET_USER_NFT_DATA = "GET_USER_NFT_DATA";
export const getUserNFTData = (payload) => {
  return {
    type: GET_USER_NFT_DATA,
    payload: payload,
  };
};
export const GET_USER_NFT_LENGTH = "GET_USER_NFT_LENGTH";
export const getUserNFTLength = (payload) => {
  return {
    type: GET_USER_NFT_LENGTH,
    payload: payload,
  };
};
export const GET_USER_NFT_VIEW_DATA = "GET_USER_NFT_VIEW_DATA";
export const getUserNFTViewData = (payload) => {
  return {
    type: GET_USER_NFT_VIEW_DATA,
    payload: payload,
  };
};

export const SELL_OFFER_LOADER = "SELL_OFFER_LOADER";
export const sellOfferLoaderData = (payload) => {
  console.log("sellOfferLoadersellOfferLoader");
  return {
    type: SELL_OFFER_LOADER,
    payload: payload,
  };
};

export const MINT_NFT_LOADER = "MINT_NFT_LOADER";
export const mintNFTLoader = (payload) => {
  return {
    type: MINT_NFT_LOADER,
    payload: payload,
  };
};
// Mint Nft
export const mintNft = (
  socketId,
  nftDataObj,
  tags,
  navigate,
  setShowNftModal
) => {
  return (dispatch) => {
    dispatch(mintNFTLoader(true));
    console.log("mint fuction call");
    let token = localStorage.getItem("nexibleToken");
    let walletAddress = localStorage.getItem("walletAddress");
    const formData = new FormData();
    formData.append("title", nftDataObj.title);
    formData.append("description", nftDataObj.description);
    formData.append("royality", nftDataObj.royality);
    formData.append("nft_img", nftDataObj.nft_img);
    formData.append("account", walletAddress);
    if (tags.length) {
      if (tags.length === 1) {
        formData.append("tags[0]", tags[0]);
      } else {
        tags?.map((tag) => formData.append("tags", tag));
      }
    }
    // let data = {
    //   account: walletAddress,
    // };
    console.log(formData, "formData");

    if (token) {
      dispatch(sellOfferLoaderData(true));

      var config = {
        method: "post",
        url: `${BASE_URL}/api/v1/user/xumm-xrpl/mint-nft/${socketId}`,
        // url: `http://localhost:5000/api/v1/user/xumm-xrpl/mint-nft/${socketId}`,
        headers: {
          token: token,
        },
        data: formData,
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          //   response.data.qr_png;
          dispatch(
            setMintNftQr({
              qr_png: response.data.qr_png,
              link: response.data.link,
            })
          );
          setShowNftModal(true);
          dispatch(mintNFTLoader(false));
          // navigate("/Explore");
          // console.log(JSON.stringify(response.data));
          // dispatch(sellOfferLoaderData(true))
          // console.log(response.data.getNfts.result.account_nfts)
          // dispatch(set(response.data.getNfts.result.account_nfts))
        })
        .catch(function (error) {
          dispatch(mintNFTLoader(false));
          // dispatch(sellOfferLoaderData(false))
          // toast.error(error.response.data.message);
          toast.error(
            error.response.data.message === "Account not found."
              ? "Your Account is Not Activated"
              : error.response.data.message
          );
          console.log(error);
        });
    } else {
      dispatch(sellOfferLoaderData(false));
    }
  };
};

export const MINT_NFT_QR = "MINT_NFT_QR";
export const setMintNftQr = (payload) => {
  return {
    type: MINT_NFT_QR,
    payload: payload,
  };
};

// Get Single NFT
export const getSingleNft = (nftId, navigate) => {
  return (dispatch) => {
    console.log("getSingleNft fuction call");

    // let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    // let data = {
    //   account: walletAddress,
    // };
    if (nftId) {
      dispatch(sellOfferLoaderData(true));

      var config = {
        method: "get",
        url: `${BASE_URL}/api/v1/user/xumm-xrpl/get-single-nft/${nftId}`,
        // headers: {
        //   token: token,
        // },
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          //   response.data.qr_png;
          console.log(response.data.nft);
          dispatch(setSingleNft(response.data.nft));
          // navigate("/Explore");
        })
        .catch(function (error) {
          // dispatch(sellOfferLoaderData(false))
          console.log(error);
        });
    } else {
      dispatch(sellOfferLoaderData(false));
    }
  };
};

export const SINGLE_NFT = "SINGLE_NFT";
export const setSingleNft = (payload) => {
  return {
    type: SINGLE_NFT,
    payload: payload,
  };
};

export const SELL_LOADER_NFT = "SELL_LOADER_NFT";
export const sellLoaderNFT = (payload) => {
  return {
    type: SELL_LOADER_NFT,
    payload: payload,
  };
};

export const SELL_OFFER_LOADER_NFT = "SELL_OFFER_LOADER_NFT";
export const sellOfferLoaderNFT = (payload) => {
  return {
    type: SELL_OFFER_LOADER_NFT,
    payload: payload,
  };
};

// Sell NFT
export const sellNft = (socketId, dataObject, nftObj, navigate) => {
  return (dispatch) => {
    console.log("sellNft fuction call");
    dispatch(sellOfferLoaderNFT(true));
    let token = localStorage.getItem("nexibleToken");
    let walletAddress = localStorage.getItem("walletAddress");
    let data = {
      account: walletAddress,
      amount: dataObject.amount,
      nftTokenId: nftObj.NFTokenID,
      _id: nftObj._id,
    };
    if (token) {
      dispatch(sellOfferLoaderData(true));

      var config = {
        method: "post",
        url: `${BASE_URL}/api/v1/user/xumm-xrpl/create-offer/${socketId}`,
        headers: {
          token: token,
        },
        data,
      };

      axios(config)
        .then(function (response) {
          dispatch(sellOfferLoaderNFT(false));
          console.log(response);
          //   response.data.qr_png;
          dispatch(sellOfferLoaderData(false));
          dispatch(
            setSellQr({
              qr_png: response.data.qr_png,
              link: response.data.link,
            })
          );

          // navigate("/Explore");
        })
        .catch(function (error) {
          dispatch(sellOfferLoaderNFT(false));
          toast.error(error.response.data.message);
          dispatch(sellOfferLoaderData(false));
          console.log(error);
        });
    } else {
      dispatch(sellOfferLoaderData(false));
      dispatch(sellOfferLoaderNFT(false));
    }
  };
};

export const SELL_QR = "SELL_QR";
export const setSellQr = (payload) => {
  return {
    type: SELL_QR,
    payload: payload,
  };
};
// remove from sell
export const removeFromSellNft = (
  data,
  setRemoveQR,
  setRemoveLoader,
  setRemoveQRModal,
  socketId
) => {
  return (dispatch) => {
    console.log(socketId,"socketId");
    let token = localStorage.getItem("nexibleToken");
    if (token) {
      dispatch(sellOfferLoaderData(true));
      var config = {
        method: "delete",
        url: `${BASE_URL}/api/v1/user/xumm-xrpl/cancel-offer/${socketId}`,
        headers: {
          token: token,
        },
        data,
      };
      axios(config)
        .then(function (response) {
          console.log(response);
          setRemoveQR({
            qr_png: response.data.qr_png,
            link: response.data.link,
          });
          setRemoveLoader(false);
          setRemoveQRModal(true);
        })
        .catch(function (error) {
          setRemoveLoader(false);
          console.log(error);
        });
    }
  };
};
export const REMOVEFROMSELLQR = "REMOVEFROMSELLQR";
export const removeSellQr = (payload) => {
  return {
    type: REMOVEFROMSELLQR,
    payload: payload,
  };
};

// Sell NFT
export const onSellNft = (page, limit) => {
  return (dispatch) => {
    let token = localStorage.getItem("nexibleToken");
    dispatch(onSellNftLoader(true));
    var config = {
      method: "get",
      url: `${BASE_URL}/api/v1/user/xumm-xrpl/nft-on-sell?page=${page}&limit=${limit}`,
      headers: {
        token: token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        dispatch(onSellNftData(response.data.nfts));
        dispatch(onSellNftLoader(false));
        dispatch(onSellNftLength(response?.data?.nftsLength[0]?.total));

        // navigate("/Explore");
      })
      .catch(function (error) {
        dispatch(onSellNftLoader(false));
        // dispatch(sellOfferLoaderData(false))
        console.log(error);
      });
  };
};

export const ON_SELL_NFT = "ON_SELL_NFT";
export const onSellNftData = (payload) => {
  return {
    type: ON_SELL_NFT,
    payload: payload,
  };
};
export const ON_SELL_NFT_LOADER = "ON_SELL_NFT_LOADER";
export const onSellNftLoader = (payload) => {
  return {
    type: ON_SELL_NFT_LOADER,
    payload: payload,
  };
};
export const ON_SELL_NFT_LENGTH = "ON_SELL_NFT_LENGTH";
export const onSellNftLength = (payload) => {
  return {
    type: ON_SELL_NFT_LENGTH,
    payload: payload,
  };
};
export const BUY_NFT_LOADER = "BUY_NFT_LOADER";
export const buyNftLoader = (payload) => {
  return {
    type: BUY_NFT_LOADER,
    payload: payload,
  };
};

export const ACCEPT_NFT_LOADER = "ACCEPT_NFT_LOADER";
export const acceptNFTLoader = (payload) => {
  return {
    type: ACCEPT_NFT_LOADER,
    payload: payload,
  };
};

// Buy NFT
export const buyNft = (socketId, nftObj, navigate) => {
  return (dispatch) => {
    console.log(nftObj, "buyNft fuction call");
    dispatch(acceptNFTLoader(true));
    let token = localStorage.getItem("nexibleToken");
    let walletAddress = localStorage.getItem("walletAddress");
    let data = {
      account: walletAddress,
      tokenOfferId: nftObj.nft_offer_index,
      _id: nftObj._id,
    };
    if (token) {
      dispatch(sellOfferLoaderData(true));

      var config = {
        method: "put",
        url: `${BASE_URL}/api/v1/user/xumm-xrpl/accept-offer/${socketId}`,
        headers: {
          token: token,
        },
        data,
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          dispatch(acceptNFTLoader(false));
          //   response.data.qr_png;
          dispatch(
            setBuyQr({ qr_png: response.data.qr_png, link: response.data.link })
          );

          // navigate("/Explore");
        })
        .catch(function (error) {
          // dispatch(sellOfferLoaderData(false))
          toast.error(
            error.response.data.message === "Account not found."
              ? "Your Account is Not Activated"
              : error.response.data.message
          );
          dispatch(acceptNFTLoader(false));
          console.log(error);
        });
    } else {
      dispatch(sellOfferLoaderData(false));
    }
  };
};

export const BUY_QR = "BUY_QR";
export const setBuyQr = (payload) => {
  return {
    type: BUY_QR,
    payload: payload,
  };
};

// Bid
export const CREATE_BID_NFT = "CREATE_BID_NFT";
export const createBidNFTLoader = (payload) => {
  return {
    type: CREATE_BID_NFT,
    payload: payload,
  };
};

export const createBidNft = (
  socketId,
  nftObj,
  handleClose,
  clearState,
  setXxummImage,
  setQRShow
) => {
  return (dispatch) => {
    console.log("buyNft fuction call");
    dispatch(createBidNFTLoader(true));
    let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    let data = nftObj;
    if (token) {
      // dispatch(sellOfferLoaderData(true));

      var config = {
        method: "post",
        url: `${BASE_URL}/api/v1/user/offer/${socketId}`,
        headers: {
          token: token,
        },
        data,
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          dispatch(createBidNFTLoader(false));
          //   response.data.qr_png;
          setXxummImage(response.data);
          dispatch(getSingleNft(nftObj.id));
          // dispatch(bidHistory(nftObj.id))
          // handleClose()
          clearState();
          setQRShow(true);
          // navigate("/Explore");
        })
        .catch(function (error) {
          // dispatch(sellOfferLoaderData(false))
          toast.error(
            error.response.data.message === "Account not found."
              ? "Your Account is Not Activated"
              : error.response.data.message
          );
          dispatch(createBidNFTLoader(false));
          console.log(error);
        });
    } else {
      dispatch(createBidNFTLoader(false));
    }
  };
};

export const BID_QR = "BID_QR";
export const setBidQr = (payload) => {
  return {
    type: BID_QR,
    payload: payload,
  };
};

// Bid
export const CREATE_AUC_NFT = "CREATE_AUC_NFT";
export const createAucNFTLoader = (payload) => {
  return {
    type: CREATE_AUC_NFT,
    payload: payload,
  };
};

export const createAucNft = (nftObj, handleClose, method) => {
  return (dispatch) => {
    console.log("buyNft fuction call");
    dispatch(createAucNFTLoader(true));
    let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    let data = nftObj;
    if (token) {
      // dispatch(sellOfferLoaderData(true));

      var config = {
        method: method,
        url: `${BASE_URL}/api/v1/user/auction`,
        headers: {
          token: token,
        },
        data,
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          dispatch(createAucNFTLoader(false));
          toast.success(response.data.message);
          handleClose();
          dispatch(fetchNftDetail(data.nftId));
          //   response.data.qr_png;
          // dispatch(
          //   setAucData({ qr_png: response.data.qr_png, link: response.data.link })
          // );

          // navigate("/Explore");
        })
        .catch(function (error) {
          // dispatch(sellOfferLoaderData(false))
          toast.error(error.response.data.message);
          dispatch(createAucNFTLoader(false));
          console.log(error);
        });
    } else {
      dispatch(createAucNFTLoader(false));
    }
  };
};

export const AUC_NFT_DATA = "AUC_NFT_DATA";
export const setAucData = (payload) => {
  return {
    type: AUC_NFT_DATA,
    payload: payload,
  };
};

// Bid
export const GET_AUC_NFT = "GET_AUC_NFT";
export const getAucNFTLoader = (payload) => {
  return {
    type: GET_AUC_NFT,
    payload: payload,
  };
};

export const getAucNft = () => {
  return (dispatch) => {
    console.log("buyNft fuction call");
    dispatch(getAucNFTLoader(true));
    let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    if (token) {
      // dispatch(sellOfferLoaderData(true));

      var config = {
        method: "get",
        url: `${BASE_URL}/api/v1/user/auction`,
        headers: {
          token: token,
        },
      };

      axios(config)
        .then(function (response) {
          console.log(response, "getAucNft");
          dispatch(getAucNFTLoader(false));
          dispatch(getAucData(response.data.auctions));
          //   response.data.qr_png;
          // dispatch(
          //   setAucData({ qr_png: response.data.qr_png, link: response.data.link })
          // );

          // navigate("/Explore");
        })
        .catch(function (error) {
          // dispatch(sellOfferLoaderData(false))
          toast.error(error.response.data.message);
          dispatch(getAucNFTLoader(false));
          console.log(error);
        });
    } else {
      dispatch(getAucNFTLoader(false));
    }
  };
};

export const GET_AUC_NFT_DATA = "GET_AUC_NFT_DATA";
export const getAucData = (payload) => {
  return {
    type: GET_AUC_NFT_DATA,
    payload: payload,
  };
};

// Bid
export const REMOVE_AUC_NFT = "REMOVE_AUC_NFT";
export const removeAucNFTLoader = (payload) => {
  return {
    type: REMOVE_AUC_NFT,
    payload: payload,
  };
};

export const removeAucNft = (id, handleClose) => {
  return (dispatch) => {
    console.log("buyNft fuction call");
    dispatch(removeAucNFTLoader(true));
    let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    if (token) {
      // dispatch(sellOfferLoaderData(true));

      var config = {
        method: "delete",
        url: `${BASE_URL}/api/v1/user/auction/remove/${id}`,
        headers: {
          token: token,
        },
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          dispatch(removeAucNFTLoader(false));
          toast.success(response.data.message);
          handleClose();
          dispatch(fetchNftDetail(id));
          //   response.data.qr_png;
          // dispatch(
          //   setAucData({ qr_png: response.data.qr_png, link: response.data.link })
          // );

          // navigate("/Explore");
        })
        .catch(function (error) {
          // dispatch(sellOfferLoaderData(false))
          toast.error(error.response.data.message);
          dispatch(removeAucNFTLoader(false));
          console.log(error);
        });
    } else {
      dispatch(removeAucNFTLoader(false));
    }
  };
};

export const REMOVE_NFT_DATA = "REMOVE_NFT_DATA";
export const removeAucData = (payload) => {
  return {
    type: REMOVE_NFT_DATA,
    payload: payload,
  };
};

// Bid
export const BID_HISTORY_LOADER = "BID_HISTORY_LOADER";
export const bidHistoryLoader = (payload) => {
  return {
    type: BID_HISTORY_LOADER,
    payload: payload,
  };
};

export const bidHistory = (id) => {
  return (dispatch) => {
    console.log(id, "BIDNFT");
    dispatch(bidHistoryLoader(true));
    let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    // if (token) {
    // dispatch(sellOfferLoaderData(true));

    var config = {
      method: "get",
      url: `${BASE_URL}/api/v1/user/offer?nft_id=${id}`,
      headers: {
        token: token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bidHistory");
        dispatch(bidHistoryLoader(false));
        // toast.success(response?.data?.message);
        dispatch(bidHistoryData(response?.data?.data));
        dispatch(fetchNftDetail(id));
        // navigate("/Explore");
      })
      .catch(function (error) {
        // dispatch(sellOfferLoaderData(false))
        toast.error(error.response.data.message);
        dispatch(bidHistoryLoader(false));
        console.log(error);
      });
    // } else {
    //   dispatch(bidHistoryLoader(false));
    // }
  };
};
export const HistoryFn = (id) => {
  return (dispatch) => {
    console.log(id, "BIDNFT");
    dispatch(bidHistoryLoader(true));
    let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    // if (token) {
    // dispatch(sellOfferLoaderData(true));

    var config = {
      method: "get",
      url: `${BASE_URL}/api/v1/user/nft/history/${id}`,
      headers: {
        token: token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data, "response?.data");
        dispatch(HistoryData(response?.data?.history));
        // navigate("/Explore");
      })
      .catch(function (error) {
        // dispatch(sellOfferLoaderData(false))
        toast.error(error.response.data.message);
        // dispatch(bidHistoryLoader(false));
        console.log(error);
      });
    // } else {
    //   dispatch(bidHistoryLoader(false));
    // }
  };
};
export const BID_HISTORY_DATA = "BID_HISTORY_DATA";
export const bidHistoryData = (payload) => {
  return {
    type: BID_HISTORY_DATA,
    payload: payload,
  };
};
export const HISTORY_DATA = "HISTORY_DATA";
export const HistoryData = (payload) => {
  return {
    type: HISTORY_DATA,
    payload: payload,
  };
};

// Bid
export const CANCEL_BID_LOADER = "CANCEL_BID_LOADER";
export const cancelbidLoader = (payload) => {
  return {
    type: CANCEL_BID_LOADER,
    payload: payload,
  };
};

export const cancelBid = (
  data,
  nftId,
  socketId,
  setOpenBidDeleteModal,
  setOpenBidDeleteModalData,
  setCancelBidLoader
) => {
  return (dispatch) => {
    console.log("buyNft fuction call");
    dispatch(cancelbidLoader(true));
    let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    if (token) {
      // dispatch(sellOfferLoaderData(true));

      var config = {
        method: "delete",
        url: `${BASE_URL}/api/v1/user/offer/${socketId}`,
        headers: {
          token: token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response, "bidHistory");
          dispatch(cancelbidLoader(false));
          setOpenBidDeleteModal(true);
          setOpenBidDeleteModalData(response?.data);
          setCancelBidLoader(false);
          toast.success(response.data.message);
          // dispatch(cancelBidData(response.data.bids))
          dispatch(fetchNftDetail(nftId));
          // dispatch(bidHistory(nftId))
          // navigate("/Explore");
        })
        .catch(function (error) {
          // dispatch(sellOfferLoaderData(false))
          setCancelBidLoader(false);
          toast.error(error.response.data.message);
          dispatch(cancelbidLoader(false));
          console.log(error);
        });
    } else {
      dispatch(cancelbidLoader(false));
    }
  };
};
export const AcceptBid = (
  data,
  nftId,
  socketId,
  setOpenBidDeleteModal,
  setOpenBidDeleteModalData,
  setAcceptLoader
) => {
  return (dispatch) => {
    // dispatch(cancelbidLoader(true));
    let token = localStorage.getItem("nexibleToken");
    // let walletAddress = localStorage.getItem("walletAddress");
    // if (token) {
    // dispatch(sellOfferLoaderData(true));
    var config = {
      method: "put",
      url: `${BASE_URL}/api/v1/user/offer/${socketId}`,
      headers: {
        token: token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bidHistory");
        dispatch(cancelbidLoader(false));
        setOpenBidDeleteModal(true);
        setOpenBidDeleteModalData(response?.data);
        setAcceptLoader(false);
        toast.success(response.data.message);
        // dispatch(cancelBidData(response.data.bids))
        // dispatch(fetchNftDetail(nftId));
        // dispatch(bidHistory(nftId))
        // navigate("/Explore");
      })
      .catch(function (error) {
        // dispatch(sellOfferLoaderData(false))
        setAcceptLoader(false);
        toast.error(error.response.data.message);
        dispatch(cancelbidLoader(false));
        console.log(error);
      });
    // } else {
    //   dispatch(cancelbidLoader(false));
    // }
  };
};

export const CANCEL_BID_DATA = "CANCEL_BID_DATA";
export const cancelBidData = (payload) => {
  return {
    type: CANCEL_BID_DATA,
    payload: payload,
  };
};
