import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from './CardUtils';
import './Checkout.scss'
const PaymentForm = ({ cardObj,
  setCardObj,
  date,
  setDate,
  cardNumber,
  setCardNumber }) => {
  const [cvc, setcvc] = useState('')
  const [expiry, setexpiry] = useState('')
  const [focus, setfocus] = useState('')
  const [name, setname] = useState('')
  const [number, setnumber] = useState('')

  // handleInputFocus = (e) => {
  //   this.setState({ focus: e.target.name });
  // }


  return (
    <div id="PaymentForm">
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focus}
        name={name}
        number={number}
      />
      <form>
        <div className="innerForm">
          <div className="field-set ">
            <label>Card Number</label>
            <div>
              <InputGroup className="mb-3 emailInput" >
                <Form.Control
                  onFocus={(ev) => setfocus("number")}
                  name="number"
                  placeholder="Card Number"
                  aria-label="Card Number"
                  aria-describedby="basic-addon2"
                  value={cardNumber}
                  pattern="[\d| ]{16,22}"
                required
                  // disabled={cardNumber}
                  onChange={(ev) => {
                    let objj = { ...cardObj }
                    objj.stripe_card_number = ev.target.value
                    setCardObj(objj)
                    let number = ev.target.value
                    console.log(number.length)
                    setnumber(number.length === 2 ? number + '/' : number)
                    //  if (number.length % 4 == 0) {
                    //     number += " ";
                    // }
                    setCardNumber(formatCreditCardNumber(number))
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="field-set ">
            <label>Card Holder Name</label>
            <div>
              <InputGroup className="mb-3 emailInput" >
                <Form.Control
                  onFocus={(ev) => setfocus("name")}
                  name="name"
                  placeholder="Card Holder Name"
                  aria-label="Card Holder Name"
                  aria-describedby="basic-addon2"
                  value={name}
                  required
                  // disabled={cardNumber}
                  onChange={(ev) => {
                    setname(ev.target.value)
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="field-set ">
            <label>Expiry</label>
            <div>
              <InputGroup className="mb-3 emailInput" >
                <Form.Control
                  onFocus={(ev) => setfocus("expiry")}
                  name="expiry"
                  placeholder="MM/YY"
                  aria-label="Expiry Date"
                  aria-describedby="basic-addon2"
                  value={date}
                  pattern="\d\d/\d\d"
                  required
                  // disabled={cardObj.stripe_card_exp_year}
                  onChange={(ev) => {
                    let number = ev.target.value
                    if(number.length <=5){

                      let objj = { ...cardObj }
                      setexpiry(ev.target.value)
                      objj.stripe_card_exp_month = number.split('/')[0]
                      objj.stripe_card_exp_year = number.split('/')[1]  
                      setDate(formatExpirationDate(number))
                      setCardObj(objj)
                    }
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="field-set ">
            <label>CVC</label>
            <div>
              <InputGroup className="mb-3 emailInput" >
                <Form.Control
                  onFocus={(ev) => setfocus("cvc")}
                  name="cvc"
                  placeholder="CVC"
                  aria-label="CVC"
                  aria-describedby="basic-addon2"
                  value={cardObj.stripe_card_cvc}
                  pattern="\d{3,4}"
                  required
                  // disabled={cardObj.stripe_card_cvc}
                  onChange={(ev) => {
                    let objj = { ...cardObj }
                    setcvc(ev.target.value)
                    objj.stripe_card_cvc = formatCVC(ev.target.value)
                    setCardObj(objj)
                  }}
                />
              </InputGroup>
            </div>
          </div>

        </div>
      </form>
    </div>
  );
}


export default PaymentForm