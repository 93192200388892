import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../components/footer";
import { createGlobalStyle } from "styled-components";
import ColumnNewRedux from "../../components/ColumnNewRedux";
import * as selectors from "../../../store/selectors";
// import { fetchHotCollections } from "../../../store/actions/thunks";
// import api from "../../../core/api";
import Guest from "../../../assets/Images/blank-profile.webp";

import { AiFillEyeInvisible, AiFillEye, AiFillCopy } from "react-icons/ai";
import {
  // getUserNFT,
  getUserNFTOnSell,
  getUserNFTViewFn,
} from "../../../store/actions/thunks/NFT";
import { useNavigate } from "react-router-dom";
import GalleryImages from "../../components/GalleryImages";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: rgb(4, 17, 243) ;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

export default function Collection({ collectionId = 1 }) {
  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [DataMenu, setDataMenu] = React.useState(false);
  const signedInUser = useSelector((e) => e.auth.signedInUser);
  const getUserViewNft = useSelector((e) => e.NFTData.getUserViewNft);
  const [userId, setUserId] = useState(false);
  // const [seedVisible, setSeedVisible] = useState(false);
  const [walletVisible, setWalletVisible] = useState(false);
  const [balanceVisible, setBalanceVisible] = useState(false);
  const xummBalance = useSelector((e) => e.auth.xummBalance);

  // const [pages, setpages] = useState(0);

  // useEffect(() => {
  //   if (pages) {
  //     setNfts(nfts ? [...nfts, ...getUserNft] : getUserNft);
  //   } else {
  //     setNfts(getUserNft);
  //   }
  // }, [pages, getUserNft]);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (!signedInUser) {
  //     navigate("/home");
  //   }
  // }, [signedInUser]);
  let url = new URL(window.location);
  let params = url.searchParams.get("u");
  useEffect(() => {
    if (params === "me") {
      setUserId(signedInUser._id);
    } else {
      dispatch(getUserNFTViewFn(params));
      setUserId(params);
    }
  }, [signedInUser]);

  const getUserNftSell = () => {
    setOpenMenu(true);
    setOpenMenu2(false);
    setOpenMenu1(false);
    setDataMenu(!DataMenu);
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    // if (userId) {
    //   // setpages(0);
    //   dispatch(getUserNFTOnSell(userId, 0, 2));
    // }
  };

  useEffect(() => {
    if (userId) {
      dispatch(getUserNFTOnSell(userId, 0, 16));
    }
  }, [userId]);
  // useEffect(() => {
  //   if (userId) {
  //     if (openMenu1) {
  //       dispatch(getUserNFT(userId, pages, 2));
  //     } else {
  //       dispatch(getUserNFTOnSell(userId, pages, 2));
  //     }
  //   }
  // }, [pages]);

  const ownedNfts = () => {
    setOpenMenu1(true);
    setOpenMenu(false);
    setOpenMenu2(false);
    setDataMenu(!DataMenu);
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    // if (userId) {
    //   // setpages(0);
    //   dispatch(getUserNFT(userId, 0, 2));
    // }
  };

  const gallery = () => {
    setOpenMenu1(false);
    setOpenMenu(false);
    setOpenMenu2(true);
    setDataMenu(!DataMenu);
    document.getElementById("Mainbtn2").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    // if (userId) {
    //   // setpages(0);
    //   dispatch(getUserNFT(userId, 0, 2));
    // }
  };

  const dispatch = useDispatch();
  const hotCollectionsState = useSelector(selectors.hotCollectionsState);
  const hotCollections = hotCollectionsState.data
    ? hotCollectionsState.data[0]
    : {};
  useEffect(() => {
    console.log(hotCollections, "hotCollections");
  }, [hotCollections]);
  // useEffect(() => {
  //   dispatch(fetchHotCollections(collectionId));
  // }, [dispatch, collectionId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const xumm = useSelector((e) => e.auth);
  const walletAccount = useSelector((e) => e.auth.walletAccount);

  return (
    <div>
      <GlobalStyles />
      <div className="profile_bannerDiv">
        <section
          id="profile_banner"
          className="jumbotron breadcumb no-bg"
          style={{
            backgroundImage: `url(${
              params === "me"
                ? signedInUser.profile_banner ||
                  "https://res.cloudinary.com/softsyncdev/image/upload/v1659708633/qprqugjanm4vchdrzgwl.png"
                : getUserViewNft?.profile_banner ||
                  "https://res.cloudinary.com/softsyncdev/image/upload/v1659708633/qprqugjanm4vchdrzgwl.png"
            })`,
          }}
        >
          <div className="mainbreadcumb"></div>
        </section>
      </div>
      <section className="container d_coll no-top no-bottom">
        <div className="row">
          <div className="col-md-12">
            <div className="d_profile">
              <div className="profile_avatar">
                <div className="d_profile_img">
                  <img
                    src={
                      params === "me"
                        ? signedInUser.profile || Guest
                        : getUserViewNft?.profile || Guest
                    }
                    alt=""
                  />
                  <i className="fa fa-check"></i>
                </div>
                <div className="profile_name">
                  <h4>
                    <div>
                      {params !== "me"
                        ? getUserViewNft?.first_name +
                          " " +
                          getUserViewNft?.last_name
                        : signedInUser.first_name +
                          " " +
                          signedInUser.last_name}
                      {/* : getUserViewNft?.first_name + " " + params === "me"
                      ? signedInUser.last_name
                      : getUserViewNft?.last_name */}
                    </div>
                    <div className="clearfix"></div>

                    {params === "me" && walletAccount && walletAccount && (
                      <>
                        <div className="collection-balance">
                          <div className="HEADER">Balance:</div>
                          {balanceVisible ? xummBalance : "********"} XRP
                          <div
                            onClick={() => setBalanceVisible(!balanceVisible)}
                            className="SeedsVisible"
                          >
                            {balanceVisible ? (
                              <AiFillEye />
                            ) : (
                              <AiFillEyeInvisible />
                            )}
                          </div>
                        </div>
                        {/* {signedInUser.account_seeds && (
                          <div className="collection-balance">
                            <div className="HEADER">Family seed:</div>
                            <span id="wallet" className="d-wallet-address">
                              {seedVisible
                                ? signedInUser.account_seeds
                                : "***********************"}
                            </span>
                            <div
                              onClick={() => setSeedVisible(!seedVisible)}
                              className="SeedsVisible"
                            >
                              {seedVisible ? (
                                <AiFillEye />
                              ) : (
                                <AiFillEyeInvisible />
                              )}
                            </div>
                            <button
                              title="Copy Text"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  signedInUser.account_seeds
                                )
                              }
                            >
                              <AiFillCopy />
                            </button>
                          </div>
                        )} */}
                        <div className="collection-balance">
                          <div className="HEADER">My Wallet:</div>
                          <span id="wallet" className="d-wallet-address">
                            {walletVisible
                              ? signedInUser.wallet_address
                              : "***********************"}
                          </span>
                          <div
                            onClick={() => setWalletVisible(!walletVisible)}
                            className="SeedsVisible"
                          >
                            {walletVisible ? (
                              <AiFillEye />
                            ) : (
                              <AiFillEyeInvisible />
                            )}
                          </div>
                          <button
                            title="Copy Text"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                signedInUser.wallet_address
                              )
                            }
                          >
                            <AiFillCopy />
                          </button>
                        </div>
                      </>
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container no-top">
        <div className="row">
          <div className="col-lg-12">
            <div className="items_filter">
              <ul className="de_nav">
                <li id="Mainbtn" className="active">
                  <span onClick={getUserNftSell}>On Sale</span>
                </li>
                <li id="Mainbtn1" className="">
                  <span onClick={ownedNfts}>Owned</span>
                </li>
                <li id="Mainbtn2" className="">
                  <span onClick={gallery}>Gallery</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {openMenu && (
          <div id="zero1" className="onStep fadeIn">
            <ColumnNewRedux
              openMenu1={openMenu1}
              // nfts={nfts}
              // setNfts={setNfts}
              // pages={pages}
              // setpages={setpages}
              userId={userId}
              shuffle
              showLoadMore={false}
            />
          </div>
        )}
        {openMenu1 && (
          <div id="zero2" className="onStep fadeIn">
            <ColumnNewRedux
              openMenu1={openMenu1}
              // nfts={nfts}
              // pages={pages}
              Owned={true}
              // setNfts={setNfts}
              // setpages={setpages}
              userId={userId}
              shuffle
              showLoadMore={false}
            />
          </div>
        )}
        {openMenu2 && (
          <div id="zero2" className="onStep fadeIn">
            <GalleryImages />
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
}
