import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as selectors from "../../store/selectors";
import * as actions from "../../store/actions/thunks";
import { clearNfts, clearFilter } from "../../store/actions";
import NftCard from "./NftCard";
import NftMusicCard from "./NftMusicCard";
import { shuffleArray } from "../../store/utils";
import { getUserNFT, getUserNFTOnSell } from "../../store/actions/thunks/NFT";

//react functional component
const ColumnNewRedux = ({
  userId,
  // pages,
  // setpages,
  openMenu1,
  showLoadMore = true,
  shuffle = false,
  authorId = null,
  Owned,
}) => {
  const dispatch = useDispatch();
  // const nftItems = useSelector(selectors.nftItems);
  const [nfts, setNfts] = useState([]);
  const [height, setHeight] = useState(0);
  const getUserNftLoader = useSelector((e) => e.NFTData.getUserNftLoader);
  const getUserNftLength = useSelector((e) => e.NFTData.getUserNftLength);
  const getUserNft = useSelector((e) => e.NFTData.getUserNft);
  const [pages, setpages] = useState(0);
  useEffect(() => {
    if (userId) {
      if (openMenu1) {
        setpages(0);
        dispatch(getUserNFT(userId, 0, 16));
      } else {
        setpages(0);
        dispatch(getUserNFTOnSell(userId, 0, 16));
      }
    }
  }, [userId]);
  useEffect(() => {
    if (pages) {
      setNfts([...nfts, ...getUserNft]);
    } else {
      setNfts(getUserNft);
    }
  }, [getUserNft]);
  useEffect(() => {
    console.log(nfts, "nftsnfts");
  }, [nfts]);
  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };
  const loadMore = () => {
    let page = pages;
    setpages(pages + 16);
    if (openMenu1) {
      dispatch(getUserNFT(userId, page + 16, 16));
    } else {
      dispatch(getUserNFTOnSell(userId, page + 16, 16));
    }
  };

  return (
    <div className="row">
      {nfts.length ? (
        <>
          {nfts &&
            nfts.map((nft, index) => (
              // nft.category === "music" ? (
              //   <NftMusicCard
              //     nft={nft}
              //     audioUrl={nft.audio_url}
              //     key={index}
              //     onImgLoad={onImgLoad}
              //     height={height}
              //   />
              // ) :
              <NftCard
                nft={nft}
                key={index}
                onImgLoad={onImgLoad}
                height={height}
              />
            ))}
          {!getUserNftLoader && pages + 16 <= getUserNftLength && (
            <div className="col-lg-12">
              <div className="spacer-single"></div>
              <span onClick={loadMore} className="btn-main lead m-auto">
                Load More
              </span>
            </div>
          )}
        </>
      ) : (
        !getUserNftLoader && (
          <div className="nothingSell">
            <p>
              {Owned
                ? "There is nothing you owned"
                : "There is nothing on sell"}
            </p>
          </div>
        )
      )}
      {getUserNftLoader && (
        <div className="LoaderNFT">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      )}
    </div>
  );
};

export default memo(ColumnNewRedux);
