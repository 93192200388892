export const categories = [
  {
    value: "Art",
    label: "Art",
  },
  {
    value: "Music",
    label: "Music",
  },
  {
    value: "Collectibles",
    label: "Collectibles",
  },
  {
    value: "Sports",
    label: "Sports",
  },
  {
    value: "Motion",
    label: "Motion",
  },
  {
    value: "Metaverse",
    label: "Metaverse",
  },
  {
    value: "Trading Cards",
    label: "Trading Cards",
  },
  {
    value: "Others",
    label: "Others",
  },
  {
    value: "Photography",
    label: "Photography",
  },
  {
    value: "Utility",
    label: "Utility",
  },
  {
    value: "Virtual Worlds",
    label: "Virtual Worlds",
  },
];

export const status = [
  {
    value: "mint",
    label: "mint",
  },
  {
    value: "auction",
    label: "auction",
  },
  {
    value: "sell",
    label: "sell",
  },
];

export const itemsType = [
  {
    value: "single_items",
    label: "Single Items",
  },
  {
    value: "bundles",
    label: "Bundles",
  },
];

export const collections = [
  {
    value: "abstraction",
    label: "Abstraction",
  },
  {
    value: "patternlicious",
    label: "Patternlicious",
  },
  {
    value: "skecthify",
    label: "Skecthify",
  },
  {
    value: "cartoonism",
    label: "Cartoonism",
  },
  {
    value: "virtuland",
    label: "Virtuland",
  },
  {
    value: "papercut",
    label: "Papercut",
  },
];
