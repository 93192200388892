import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../constant";

export const searchDataFn = (value, page, limit) => {
  return (dispatch) => {
    dispatch(getFilterLoader(true));
    var config = {
      method: "get",
      url: `${BASE_URL}/api/v1/user/search/nft?text=${value}&page=${page}&limit=${limit}`,
    };
    axios(config)
      .then(function (response) {
        // if (response.data) {
        console.log(response?.data);
        dispatch(getFilterData(response?.data?.nfts));
        dispatch(getFilterDataLength(response?.data?.nftsLength[0]?.total));
        dispatch(getFilterSearch(value));
        dispatch(getFilterLoader(false));
        // localStorage.setItem('nexibleToken', response.data.token)
      })
      .catch(function (error) {
        dispatch(getFilterLoader(false));
        console.log(error);
      });
  };
};
export const searchTagsDataFn = (value, page, limit, search) => {
  return (dispatch) => {
    dispatch(getFilterLoader(true));
    var config = {
      method: "get",
      // `&page=0&limit=25&nftType=mint`
      url: `${BASE_URL}/api/v1/user/search/nft/tag?${value}&page=${page}&limit=${limit}`,
    };
    axios(config)
      .then(function (response) {
        // if (response.data) {
        console.log(response?.data);
        dispatch(getFilterData(response?.data?.nfts));
        dispatch(getFilterSearch(''));
        dispatch(getFilterDataLength(response?.data?.nftsLength[0]?.total));
        dispatch(getFilterSearchTags(search));
        dispatch(getFilterLoader(false));
        // localStorage.setItem('nexibleToken', response.data.token)
      })
      .catch(function (error) {
        dispatch(getFilterLoader(false));
        console.log(error);
      });
  };
};
export const emailSubscribeFn = (value, setLoader) => {
  return (dispatch) => {
    setLoader(true);
    // dispatch(getFilterLoader(true));
    console.log({ email: value }, "value");
    var config = {
      method: "post",
      // `&page=0&limit=25&nftType=mint`
      url: `${BASE_URL}/api/v1/subscribe`,
      data: { email: value },
    };
    axios(config)
      .then(function (response) {
        setLoader(false);
        toast.success("Email subscribed successfully");
      })
      .catch(function (error) {
        setLoader(false);
        toast.error(error?.response?.data?.message);
        dispatch(getFilterLoader(false));
        console.log(error.response, "error");
      });
  };
};
export const FILTEREDNFTDATA = "FILTEREDNFTDATA";
export const getFilterData = (payload) => {
  return {
    type: FILTEREDNFTDATA,
    payload: payload,
  };
};
export const FILTEREDNFTLOADER = "FILTEREDNFTLOADER";
export const getFilterLoader = (Bool) => {
  return {
    type: FILTEREDNFTLOADER,
    payload: Bool,
  };
};
export const FILTEREDNFTSEARCH = "FILTEREDNFTSEARCH";
export const getFilterSearch = (Bool) => {
  return {
    type: FILTEREDNFTSEARCH,
    payload: Bool,
  };
};
export const FILTEREDNFTSEARCHTAGS = "FILTEREDNFTSEARCHTAGS";
export const getFilterSearchTags = (Bool) => {
  return {
    type: FILTEREDNFTSEARCHTAGS,
    payload: Bool,
  };
};
export const FILTEREDNFTDATALENGTH = "FILTEREDNFTDATALENGTH";
export const getFilterDataLength = (Bool) => {
  return {
    type: FILTEREDNFTDATALENGTH,
    payload: Bool,
  };
};
