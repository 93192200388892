import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NftCard from "./NftCard";
import {
  searchDataFn,
  searchTagsDataFn,
} from "../../store/actions/thunks/filters";
import Spinner from "../Pages/Spinner";

const ColumnFilterCards = ({
  pages,
  setpages,
  nfts,
  setNfts,
  showLoadMore = true,
  shuffle = false,
  authorId = null,
}) => {
  const dispatch = useDispatch();
  // const nfts = nftItems ? shuffle ? shuffleArray(nftItems) : nftItems : [];
  const [height, setHeight] = useState(0);

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };
  const FilteredNftData = useSelector((e) => e.NftFilters.FilteredNftData);
  const FilteredNftDataLength = useSelector(
    (e) => e.NftFilters.FilteredNftDataLength
  );
  const FilteredNftLoader = useSelector((e) => e.NftFilters.FilteredNftLoader);
  const FilteredNftSearch = useSelector((e) => e.NftFilters.FilteredNftSearch);
  const FilteredNftSearchTags = useSelector(
    (e) => e.NftFilters.FilteredNftSearchTags
  );
  useEffect(() => {
    setNfts(
      FilteredNftData && nfts ? [...nfts, ...FilteredNftData] : FilteredNftData
    );
  }, [FilteredNftData]);

  const loadMore = () => {
    if (pages <= FilteredNftDataLength) {
      if (FilteredNftSearch) {
        let page = pages;
        setpages(pages + 16);
        dispatch(searchDataFn(FilteredNftSearch, page + 16, 4));
      } else {
        let page = pages;
        setpages(pages + 16);
        dispatch(
          searchTagsDataFn(
            FilteredNftSearchTags,
            page + 16,
            16,
            FilteredNftSearchTags
          )
        );
      }
    }
  };
  return (
    <div className="row">
      {/* {nfts.length > 1 && nfts.slice(0,10).map((nft, index) => { */}
      {nfts.length ? (
        <>
          {nfts?.map((nft, index) => {
            return (
              <NftCard
                // nft={nft}
                // audioUrl={nft.audio_url}
                // key={index}
                // onImgLoad={onImgLoad}
                // height={height}
                nft={nft}
                key={index}
                onImgLoad={onImgLoad}
                height={height}
              />
            );
          })}
          {/* {showLoadMore && nfts.length <= 20 && ( */}
          {!FilteredNftLoader && pages + 16 <= FilteredNftDataLength && (
            <div className="col-lg-12">
              <div className="spacer-single"></div>
              <span onClick={loadMore} className="btn-main lead m-auto">
                Load More
              </span>
            </div>
          )}
          {}
          {/* )} */}
        </>
      ) : !FilteredNftLoader ? (
        <div className="nothingSell">
          <p>No Data available</p>
        </div>
      ) : null}
      {FilteredNftLoader ? (
        <div className="Spinner-Parent">
          <Spinner />
        </div>
      ) : null}
    </div>
  );
};

export default memo(ColumnFilterCards);
