import { InputNumber } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createBidNft } from "../../store/actions/thunks/NFT";

function BidModal({
  show,
  handleClose,
  nftObj,
  socketId,
  nftId,
  singleNft,
  bidHistoryData,
  setXxummImage,
  xummImage,
  bidOfferQRLoader,
  setQRShow,
}) {
  // const [lastBidOffer, setLastBidOffer] = useState(false);
  const [bid_priceNumber, setbid_priceNumber] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((e) => e.auth.signedInUser);
  // const [socketId, setSocketId] = React.useState("");
  const createBidOfferLoader = useSelector(
    (e) => e.NFTData.createBidOfferLoader
  );
  const [obj, setObj] = useState({
    buyer_id: "",
    buyer_xrp_address: "",
    nft_id: "",
    owner_id: "",
    price: "",
  });
  function clearState() {
    setObj({
      buyer_id: "",
      buyer_xrp_address: "",
      nft_id: "",
      owner_id: "",
      price: "",
    });
    // setLastBidOffer(false);
  }
  function bidNow() {
    if (bid_priceNumber > 0) {
      let data = {
        ...obj,
        owner_id: nftObj.owner._id,
        nft_id: nftId,
        buyer_id: user._id,
        buyer_xrp_address: user.wallet_address,
      };
      dispatch(
        createBidNft(
          socketId,
          data,
          handleClose,
          clearState,
          setXxummImage,
          setQRShow
        )
      );
    } else {
      toast.error("Please Enter The Correct Amount");
    }
    // console.log(data)
  }

  // useEffect(() => {
  //   if (bidHistoryData && bidHistoryData.length) {
  //     setLastBidOffer(bidHistoryData[0]);
  //   } else {
  //     setLastBidOffer(false);
  //   }
  // }, [bidHistoryData]);
  useEffect(() => {}, []);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setXxummImage(false);
          handleClose();
        }}
        className="bidModal"
      >
        <Modal.Body>
          {/* {bidOfferQRLoader ? (
            <div className="QrSellModal">
              <div className="QrSellModalInner">
                <div className="spinner-borderDiv">
                  <div className="spinner-border " role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </div>
            </div>
          ) : xummImage ? (
            <>
              <div className="QrSellModal">
                <div className="QrSellModalInner">
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>
                        Scan QR code from XUMM Mobile Application to proceed
                        your offer
                      </h4>
                      <img src={xummImage.qr_png} alt="Sell Png" />
                    </div>
                    <div className="removeUnderlineDiv">
                      <a
                        href={xummImage.link}
                        target="_blank"
                        className="removeUnderline"
                      >
                        XUMM Wallet
                      </a>
                    </div>
                  </>
                </div>
              </div>
            </>
          ) : ( */}
          <>
            <div className="heading">
              <h3>Create Offer</h3>
            </div>
            <p>You are about to create an offer</p>
            <div className="bidAmountDiv">
              <div className="heading mt-3">
                <p>Price</p>
                <div className="subtotal">{nftObj?.amount / 1000000} XRP</div>
              </div>
            </div>
            <div className="heading bidAmmountField">
              <p>Offer Amount</p>
              <InputNumber
                type="number"
                value={obj.bid_price}
                onChange={(ev) => {
                  setbid_priceNumber(ev);
                  let objj = { ...obj };
                  if (ev > 0) {
                    objj.price = String(Number(ev) * 1000000);
                  } else {
                    objj.price = String(0);
                  }
                  setObj(objj);
                }}
              />
              {/* <div className="subtotal">{nftObj?.amount / 1000000} XRP</div> */}
            </div>
            <button
              className="btn-main lead mb-5"
              onClick={bidNow}
              disabled={false}
            >
              {createBidOfferLoader ? (
                <div className="spinner-borderDiv">
                  <div className="spinner-border " role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              ) : (
                `Bid Now`
              )}
            </button>
          </>
          {/* )} */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BidModal;
