import { Popover, Steps } from "antd";
import React from "react";
export default function SignUpSteps({ activeTab }) {
  // const description = "Verification";
  // const description2 = "Information";
  // const description3 = "To Xumm";
  // const description4 = "XRP";
  // const description5 = "Details";
  return (
    <Steps
      current={
        activeTab === "Verify"
          ? 0
          : activeTab === "addInfo"
          ? 1
          : activeTab === "Xumm"
          ? 2
          : activeTab === "Pricing"
          ? 3
          : activeTab === "addCard"
          ? 4
          : 0
      }
      className="steps-signup"
      progressDot={customDot}
      items={[
        {
          title: `Email Verification`,
        },
        {
          title: "Add Information",
        },
        {
          title: "Connect To Xumm",
        },
        {
          title: "Buy XRP",
        },
        {
          title: "Payments Details",
        },
      ]}
    />
  );
}

const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
