import { Axios, Canceler } from "../../../core/axios";
import * as actions from "../../actions";
import api from "../../../core/api";
import { BASE_URL } from "../../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { signedIn } from "./auth";
export const editPorofileFn = (formData, setUpdateLoader) => {
  return (dispatch) => {
    // var data = JSON.stringify(obj);

    var config = {
      method: "put",
      url: `${BASE_URL}/api/v1/user/profile`,
      headers: {
        token: localStorage.getItem("nexibleToken"),
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        setUpdateLoader(false);
        // localStorage.setItem("nexibleToken", response.data.token);
        localStorage.setItem("nexibleUser", JSON.stringify(response.data.user));
        toast.success(response?.data?.message);
        dispatch(signedIn());
        // redirectUser("/Profile");
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        setUpdateLoader(false);
      });
  };
};
export const fetchAuthorList = (authorId) => async (dispatch) => {
  dispatch(actions.getAuthorList.request(Canceler.cancel));

  try {
    let filter = authorId ? "id=" + authorId : "";
    const { data } = await Axios.get(`${api.baseUrl}${api.authors}?${filter}`, {
      cancelToken: Canceler.token,
      params: {},
    });

    dispatch(actions.getAuthorList.success(data));
  } catch (err) {
    dispatch(actions.getAuthorList.failure(err));
  }
};

export const fetchAuthorRanking = () => async (dispatch) => {
  dispatch(actions.getAuthorRanking.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${api.baseUrl}${api.authorsSales}`, {
      cancelToken: Canceler.token,
      params: {},
    });

    dispatch(actions.getAuthorRanking.success(data));
  } catch (err) {
    dispatch(actions.getAuthorRanking.failure(err));
  }
};
