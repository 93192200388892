import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import {
  addCardData,
  cancelPayment,
  confirmPayment,
  createOtpData,
  createPayment,
  VerifyEmail,
  verifyOTP,
} from "../../../store/actions/thunks/auth";
import Footer from "../../components/footer";
import Pricing from "../../components/pricing";
import pic1 from "./../../../assets/Images/blank-profile.webp";
import verified from "./../../../assets/Images/verified-symbol-icon.webp";
import "react-credit-cards/es/styles-compiled.css";
import PaymentForm from "./StripeForm";
import QRCode from "react-qr-code";
import { AiFillEyeInvisible } from "react-icons/ai";
import XummWalletModal from "./XummWalletModal";
import SignUpSteps from "./signUpSteps";

const GlobalStyles = createGlobalStyle`
@media only screen and (max-width: 1199px) {
 .navbar{
   background: rgb(4, 17, 243) ;
 }
 .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
   background: #fff;
 }
 .item-dropdown .dropdown a{
   color: #fff !important;
 }
}
`;

function CreateWallet() {
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState();
  const [profileImageTemp, setProfileImageTemp] = useState();
  const [profileBanner, setProfileBanner] = useState();
  const [profileBannerTemp, setProfileBannerTemp] = useState();
  const [show, setShow] = useState(false);
  const [showConnect, setShowConnect] = useState(false);
  const [VerifyOtp, setVerifyOtp] = useState("");
  const [VerifyOtpShow, setVerifyOtpShow] = useState(false);
  const [VerifyEmailLoader, setVerifyEmailLoader] = useState(false);
  const [userData, setUserData] = useState({});
  const [QrCodeSeedToggle, setQrCodeSeedToggle] = useState(false);
  const [QrCodeDownloadToggle, setQrCodeDownloadToggle] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleCloseConnect = () => setShowConnect(false);
  const handleShowConnect = () => {
    setShowConnect(true);
  };
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    otp_key: "",
    password: "",
  });
  const otpLoader = useSelector((e) => e.auth.connectXummLoader);
  const otpVerifyLoader = useSelector((e) => e.auth.otpVerifyLoader);
  const addCardLoader = useSelector((e) => e.auth.addCardLoader);
  const confirmPaymentLoader = useSelector((e) => e.auth.confirmPaymentLoader);
  const cancelPaymentLoader = useSelector((e) => e.auth.cancelPaymentLoader);
  const signedInUser = useSelector((e) => e.auth.signedInUser);
  const paymentId = useSelector((e) => e.auth.paymentId);
  const [date, setDate] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    let userLS = localStorage.getItem("nexibleUser");
    if (JSON.parse(userLS) && JSON.parse(userLS).wallet_address) {
      console.log(userLS);
      setData({
        ...data,
        wallet_address: JSON.parse(userLS).wallet_address,
      });
    }
    console.log(signedInUser, "signedInUser");
    let xumm = JSON.parse(userLS);
    if (!xumm?.xumm_scan && xumm) {
      setActiveTab("Xumm");
      console.log(xumm,'xummxumm')
      setUserData(xumm);
    } else if (JSON.parse(userLS)?.email) {
      // setActiveTab("Xumm");
      setActiveTab("Pricing");
      let userD = JSON.parse(userLS);
      if (userD.stripe_card_number) {
        setUser(userD);
        setCardObj({
          stripe_card_number: userD.stripe_card_number,
          stripe_card_exp_month: userD.stripe_card_exp_month,
          stripe_card_exp_year: userD.stripe_card_exp_year,
          stripe_card_cvc: userD.stripe_card_cvc,
        });
        setDate(userD.stripe_card_exp_month + "/" + userD.stripe_card_exp_year);

        let number = userD.stripe_card_number;
        if (number.length % 4 == 0) {
          number += " ";
        }
        setCardNumber(number);
      }
    } else {
      setActiveTab("Verify");
    }
  }, []);
  useEffect(() => {
    console.log(signedInUser, "signedInUser");
    if ((!signedInUser?.email && signedInUser) || !signedInUser) {
      setActiveTab("Verify");
    } else if (
      (signedInUser?.xumm_scan && signedInUser) ||
      signedInUser?.connect_wallet
    ) {
      setActiveTab("Pricing");
    } else {
      setActiveTab("Xumm");
    }
  }, [signedInUser]);
  const sendOtp = (e) => {
    e.preventDefault();
    dispatch(verifyOTP(data.email, setVerifyOtpShow));
  };

  const create = (e) => {
    e.preventDefault();
    console.log(data);
    let updatedObj = {
      ...data,
      profile: profileImage,
      profile_banner: profileBanner,
    };

    console.log(updatedObj);

    let formData = new FormData();

    for (let prop in updatedObj) {
      if (updatedObj[prop]) {
        formData.append(prop, updatedObj[prop]);
      }
    }
    dispatch(createOtpData(formData, setActiveTab, setUserData));
  };

  const handleProfilePicture = (event) => {
    let file = event.target.files[0];
    setProfileImage(file);
    let reader = new FileReader();
    reader.onloadend = () => {
      setProfileImageTemp(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleProfileBanner = (event) => {
    let file = event.target.files[0];
    setProfileBanner(file);
    let reader = new FileReader();
    reader.onloadend = () => {
      setProfileBannerTemp(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [plan, setPlan] = useState("");

  const SelectPlan = (plan) => {
    setPlan(plan);
    setActiveTab("addCard");
  };
  const [cardObj, setCardObj] = useState({
    stripe_card_number: "",
    stripe_card_exp_month: "",
    stripe_card_exp_year: "",
    stripe_card_cvc: "",
  });
  const redirect = () => {
    navigate("/User?u=me");
  };
  const addCard = (e) => {
    e.preventDefault();
    // setShow(true)
    // console.log(cardObj)
    if (user.stripe_card_number) {
      dispatch(createPayment((plan * 100).toString(), setShow));
    } else {
      dispatch(addCardData(cardObj, setShow, (plan * 100).toString()));
    }
  };

  useEffect(() => {
    console.log(paymentId, "paymentId");
  }, [paymentId]);

  const confirmPaymentFn = () => {
    dispatch(confirmPayment(paymentId, setShow, redirect));
  };
  const cancelPaymentFn = () => {
    dispatch(cancelPayment(paymentId, setShow));
  };
  const verifyOtpFn = () => {
    if (VerifyOtp.length && VerifyOtpShow) {
      setVerifyEmailLoader(true);
      dispatch(
        VerifyEmail(
          {
            email: data.email,
            otp_key: VerifyOtp,
          },
          setActiveTab,
          setVerifyEmailLoader,
          setVerifyOtpShow
        )
      );
      setVerifyOtp("");
    }
  };
  // const description = "You can hover on the dot.";
  // const customDot = (dot, { status, index }) => (
  //   <Popover
  //     content={
  //       <span>
  //         step {index} status: {status}
  //       </span>
  //     }
  //   >
  //     {dot}
  //   </Popover>
  // );
  return (
    <div>
      <GlobalStyles />

      <section
        className="jumbotron breadcumb no-bg"
        style={{ backgroundImage: `url(${"./img/background/subheader.jpg"})` }}
      >
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">
                  {activeTab === "Pricing"
                    ? `Pricing`
                    : activeTab === "addCard"
                    ? `Add Payment Method`
                    : `Create Wallet`}{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container createWalletPage">
        <div className="col-xs-12 mt-1">
          <div id="tabs2" className="tabs2None">
            <div style={{ marginBottom: "30px" }}>
              <SignUpSteps activeTab={activeTab} />
            </div>
            <Tabs
              fill
              activeKey={activeTab}
              onSelect={(e) => {
                setActiveTab(e);
                // navigate(`?active=${e}`)
              }}
            >
              <Tab eventKey="Verify" title="Verify Email" disabled>
                <div className="innerTab">
                  <div className="col-md-7 col-sm-12">
                    <Form className="form-border">
                      <div className="field-set">
                        {(!VerifyOtpShow && <label>Email</label>) || (
                          <label>Enter Otp</label>
                        )}
                        <div>
                          {!VerifyOtpShow && (
                            <InputGroup className="mb-3 emailInput">
                              <Form.Control
                                value={data.email}
                                disabled={VerifyOtpShow ? true : otpLoader}
                                placeholder="Enter Your Email"
                                aria-label="Enter Your Email"
                                aria-describedby="basic-addon2"
                                onChange={(ev) => {
                                  let obj = { ...data };
                                  obj.email = ev.target.value;
                                  setData(obj);
                                }}
                              />
                              <button
                                className="otpbutton"
                                onClick={sendOtp}
                                disabled={otpLoader}
                              >
                                {otpLoader ? (
                                  <div className="">
                                    <div
                                      class="spinner-border text-primary"
                                      role="status"
                                    ></div>
                                  </div>
                                ) : (
                                  `Send OTP`
                                )}
                              </button>
                            </InputGroup>
                          )}
                          {VerifyOtpShow && (
                            <>
                              <div className="mb-3 mt-1">
                                <h6>
                                  We have send you a verification code on{" "}
                                  <strong>{data.email}</strong>
                                </h6>
                              </div>
                              <InputGroup className="mb-3 emailInput">
                                <Form.Control
                                  value={VerifyOtp}
                                  disabled={VerifyEmailLoader}
                                  // type="password"
                                  placeholder="Enter otp"
                                  aria-label="Enter otp"
                                  aria-describedby="basic-addon2"
                                  onChange={(ev) =>
                                    setVerifyOtp(ev.target.value)
                                  }
                                />
                                <button
                                  className="otpbutton"
                                  onClick={verifyOtpFn}
                                  disabled={VerifyEmailLoader}
                                >
                                  {VerifyEmailLoader ? (
                                    <div className="">
                                      <div
                                        class="spinner-border text-primary"
                                        role="status"
                                      ></div>
                                    </div>
                                  ) : (
                                    `Verify Otp`
                                  )}
                                </button>
                              </InputGroup>
                              <h6>
                                Didn't receive Email{" "}
                                <a
                                  onClick={sendOtp}
                                  disabled={otpLoader}
                                  style={{
                                    color: "#1890ff",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Resend
                                </a>
                              </h6>
                            </>
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="addInfo" title="Add Information" disabled>
                <div className="innerTab">
                  <div className="col-md-10 col-sm-12 ">
                    <Form className="form-border">
                      <div className="innerForm">
                        <div className="field-set col-sm-5">
                          <label>Profile image</label>
                          <i
                            className="fa fa-info-circle id-color-2"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Recommend 400 x 400. Max size: 50MB. Click the image to upload."
                            aria-label="Recommend 400 x 400. Max size: 50MB. Click the image to upload."
                          ></i>
                          <div>
                            <img
                              src={profileImageTemp ? profileImageTemp : pic1}
                              id="click_profile_img"
                              className="d-profile-img-edit img-fluid"
                              alt=""
                              style={{
                                margin: "10px 0px",
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                              }}
                            />
                            <input
                              name="profile_image"
                              type="file"
                              id="upload_profile_img"
                              onChange={(event) => {
                                handleProfilePicture(event);
                              }}
                            />
                          </div>
                        </div>
                        <div className="field-set col-sm-5">
                          <label>
                            Profile banner{" "}
                            <i
                              className="fa fa-info-circle id-color-2"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                              aria-label="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                            ></i>
                          </label>
                          <div>
                            <img
                              src={profileBannerTemp}
                              id="click_banner_img"
                              className="d-banner-img-edit img-fluid"
                              alt=""
                            />
                            <input
                              name="profile_banner"
                              type="file"
                              id="upload_banner_img"
                              onChange={(event) => {
                                handleProfileBanner(event);
                              }}
                            />
                          </div>
                        </div>
                        <div className="field-set col-sm-5">
                          <label>First Name</label>
                          <div>
                            <InputGroup className="mb-3 emailInput">
                              <Form.Control
                                value={data.first_name}
                                disabled={otpVerifyLoader}
                                placeholder="Enter Your First Name"
                                aria-label="Enter Your First Name"
                                aria-describedby="basic-addon2"
                                onChange={(ev) => {
                                  let obj = { ...data };
                                  obj.first_name = ev.target.value;
                                  setData(obj);
                                }}
                              />
                            </InputGroup>
                          </div>
                        </div>
                        <div className="field-set col-sm-5">
                          <label>Last Name</label>
                          <div>
                            <InputGroup className="mb-3 emailInput">
                              <Form.Control
                                value={data.last_name}
                                disabled={otpVerifyLoader}
                                placeholder="Enter Your Last Name"
                                aria-label="Enter Your Last Name"
                                aria-describedby="basic-addon2"
                                onChange={(ev) => {
                                  let obj = { ...data };
                                  obj.last_name = ev.target.value;
                                  setData(obj);
                                }}
                              />
                            </InputGroup>
                          </div>
                        </div>
                        <div className="field-set col-sm-5">
                          <label>Email</label>
                          <div>
                            <InputGroup className="mb-3 emailInput">
                              <Form.Control
                                value={data.email}
                                disabled={true}
                                // disabled={otpVerifyLoader}
                                placeholder="Enter Your Email"
                                aria-label="Enter Your Email"
                                aria-describedby="basic-addon2"
                                onChange={(ev) => {
                                  let obj = { ...data };
                                  obj.email = ev.target.value;
                                  setData(obj);
                                }}
                              />
                            </InputGroup>
                          </div>
                        </div>
                        <div className="field-set col-sm-5">
                          <label>Password</label>
                          <div>
                            <InputGroup className="mb-3 emailInput">
                              <Form.Control
                                type="password"
                                value={data.password}
                                disabled={otpVerifyLoader}
                                placeholder="ENTER Password"
                                aria-label="ENTER Password"
                                aria-describedby="basic-addon2"
                                onChange={(ev) => {
                                  let obj = { ...data };
                                  obj.password = ev.target.value;
                                  setData(obj);
                                }}
                              />
                            </InputGroup>
                          </div>
                        </div>
                        <div className="field-set col-sm-5">
                          <button
                            className="createButton"
                            onClick={create}
                            disabled={otpVerifyLoader}
                          >
                            {otpVerifyLoader ? (
                              <div className="">
                                <div
                                  class="spinner-border text-primary"
                                  role="status"
                                ></div>
                              </div>
                            ) : (
                              `Create User`
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Xumm" title="Connect to xumm" disabled>
                <div className="innerTab">
                  <div className="col-md-10 col-sm-12 ">
                    <div className="field-set">
                      <h3>Connect Wallet to Xumm</h3>
                      <div className="scanQr-parent">
                        <div className="scanQr">
                          <h5 className="mb-3">
                            scan the Qr code to Download Xumm
                          </h5>
                          {/* {signedInUser?.account_seeds && ( */}
                          <div className="Qrcode-parent">
                            <div
                              className={`overlay ${QrCodeDownloadToggle}`}
                              // onClick={() => setQrCodeDownloadToggle(true)}
                            >
                              <AiFillEyeInvisible />
                            </div>
                            <QRCode
                              value={`https://play.google.com/store/apps/details?id=com.xrpllabs.xumm`}
                            />
                            <h5
                              onMouseOver={() => setQrCodeDownloadToggle(true)}
                              onMouseOut={() => setQrCodeDownloadToggle(false)}
                              style={{
                                textAlign: "center",
                                paddingTop: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <strong>Hover to show</strong>
                            </h5>
                          </div>
                        </div>
                        <div className="scanQr">
                          <h5 className="mb-3">
                            scan the Qr code to import account
                          </h5>
                          {/* <p>{signedInUser?.account_seeds}</p> */}
                          <div className="Qrcode-parent">
                            <div
                              className={`overlay ${QrCodeSeedToggle}`}
                              // onClick={() => setQrCodeSeedToggle(true)}
                            >
                              <AiFillEyeInvisible />
                            </div>
                            {userData?.account_seeds && (
                              <QRCode value={userData?.account_seeds} />
                            )}
                            <h5
                              style={{
                                textAlign: "center",
                                paddingTop: "10px",
                                cursor: "pointer",
                              }}
                              onMouseOver={() => setQrCodeSeedToggle(true)}
                              onMouseOut={() => setQrCodeSeedToggle(false)}
                            >
                              <strong>Hover to show</strong>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="selectPlanText-2">
                      <div className="mainside">
                        <XummWalletModal
                          show={showConnect}
                          setShow={setShowConnect}
                          handleClose={handleCloseConnect}
                          handleShow={handleShowConnect}
                          setActiveTab={setActiveTab}
                          ScanConnectWallet={
                            signedInUser?.connect_wallet
                              ? false
                              : !signedInUser?.xumm_scan || true
                          }
                          userData={userData}
                          // connectButonHide={walletAccount ? true : false}
                        />
                      </div>
                      <a
                        className="pricing"
                        style={{
                          justifyContent: "flex-end",
                          margin: "0",
                          marginLeft: "20px",
                        }}
                        onClick={() => {
                          if (signedInUser?.xumm_scan) {
                            setActiveTab("Pricing");
                          }
                        }}
                        disabled={!signedInUser?.xumm_scan}
                      >
                        {" "}
                        next
                      </a>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Pricing" title="Pricing" disabled>
                <div className="innerTab">
                  <div className="col-md-8 col-sm-12">
                    <Pricing plan={plan} setPlan={SelectPlan} />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="addCard" title="Add Debit/Credit Card" disabled>
                <div className="innerTab">
                  <PaymentForm
                    cardObj={cardObj}
                    setCardObj={setCardObj}
                    date={date}
                    setDate={setDate}
                    cardNumber={cardNumber}
                    setCardNumber={setCardNumber}
                  />
                  <div className=" d-flex addCardButtonDiv">
                    <p
                      className="selectPlanText"
                      onClick={() => {
                        setActiveTab("Pricing");
                      }}
                    >
                      {" "}
                      ← Select Plan
                    </p>
                    <button
                      className="createButton addCardButton"
                      onClick={addCard}
                      disabled={addCardLoader}
                    >
                      {addCardLoader ? (
                        <div className="">
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      ) : cardObj.stripe_card_number ? (
                        `Make payment`
                      ) : (
                        `Add Card`
                      )}
                    </button>
                  </div>
                  {/* <Form className="form-border">
                                            <div className="innerForm">
                                                <div className="field-set col-sm-12">
                                                    <label>Card Number</label>
                                                    <div>
                                                        <InputGroup className="mb-3 emailInput" >
                                                            <Form.Control
                                                                placeholder="Card Number"
                                                                aria-label="Card Number"
                                                                aria-describedby="basic-addon2"
                                                                value={cardNumber}
                                                                // disabled={cardNumber}
                                                                onChange={(ev) => {
                                                                    let objj = { ...cardObj }
                                                                    objj.stripe_card_number = ev.target.value
                                                                    setCardObj(objj)
                                                                    let number = ev.target.value
                                                                    //  if (number.length % 4 == 0) {
                                                                    //     number += " ";
                                                                    // }
                                                                    setCardNumber(number)
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                                <div className="field-set col-sm-5">
                                                    <label>Expiry</label>
                                                    <div>
                                                        <InputGroup className="mb-3 emailInput" >
                                                            <Form.Control
                                                                placeholder="MM/YY"
                                                                aria-label="Expiry Date"
                                                                aria-describedby="basic-addon2"
                                                                value={date}
                                                                // disabled={cardObj.stripe_card_exp_year}
                                                                onChange={(ev) => {
                                                                    let number = ev.target.value
                                                                    let objj = { ...cardObj }
                                                                    objj.stripe_card_exp_month = number.split('/')[0]
                                                                    objj.stripe_card_exp_year = number.split('/')[1]
                                                                    setDate(number)
                                                                    // let objj = { ...cardObj }
                                                                    // objj. = ev.target.value
                                                                    setCardObj(objj)
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                                <div className="field-set col-sm-5">
                                                    <label>CVC</label>
                                                    <div>
                                                        <InputGroup className="mb-3 emailInput" >
                                                            <Form.Control
                                                                placeholder="CVC"
                                                                aria-label="CVC"
                                                                aria-describedby="basic-addon2"
                                                                value={cardObj.stripe_card_cvc}
                                                                // disabled={cardObj.stripe_card_cvc}
                                                                onChange={(ev) => {
                                                                    let objj = { ...cardObj }
                                                                    objj.stripe_card_cvc = ev.target.value
                                                                    setCardObj(objj)
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button className="createButton" onClick={addCard} disabled={addCardLoader}>{addCardLoader ?
                                                        <div className=''>
                                                            <div class="spinner-border text-primary" role="status">
                                                            </div>
                                                        </div>
                                                        : cardObj.stripe_card_number ? `Make payment` : `Add Card`}</button>
                                                    <p className="selectPlanText" onClick={() => {
                                                        setActiveTab('Pricing')
                                                    }}> ← Select Plan</p>
                                                </div>
                                            </div>
                                        </Form> */}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} className="addCardModal">
          <Modal.Body>
            <div className="verified">
              <img className="Verified-Img" src={verified} />
              <h3>Your card detail has been saved</h3>
            </div>
            <div className="confirmation">
              <p>Are you sure you want to purchase basic plan</p>
            </div>
            <div className="buttonDiv">
              <button
                className="cancelButton"
                onClick={() => cancelPaymentFn()}
                disabled={cancelPaymentLoader}
              >
                {cancelPaymentLoader ? (
                  <div className="">
                    <div
                      class="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  `Cancel`
                )}
              </button>
              <button
                className="purchase"
                onClick={() => confirmPaymentFn()}
                disabled={confirmPaymentLoader}
              >
                {confirmPaymentLoader ? (
                  <div className="">
                    <div
                      class="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  `Purchase`
                )}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <Footer />
    </div>
  );
}

export default CreateWallet;
