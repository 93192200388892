import './App.css';
import AppInner from './components/app';

function App() {
  return (
   <AppInner/>
  );
}

export default App;
