import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import "./suspenseImg.scss";
const SuspenseImg = ({ src, height, Infoheight, ...rest }) => {
  const [Loader, setLoader] = useState(true);
  useEffect(() => {
    // throw new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      // resolve();
      setTimeout(()=>{
        setLoader(false);
      },1000)
      // console.log(img, "IMG");
    };
    img.src = src;
    return () => {
      img.onload = null;
    };
    // });
  }, []);
  // console.log(Infoheight);
  return Loader ? (
    <Skeleton.Image
      // className={height ? "ImgHeight" : Infoheight ? "Infoheight" : "Height"}
      {...rest}
      active={true}
      width={"100%"}
    />
  ) : (
    <img alt="" src={src} {...rest} />
  );
};
export default SuspenseImg;
