import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import * as selectors from '../../store/selectors';
import { fetchAuthorList } from "../../store/actions/thunks";
import Charities from "./Charities";

const CharityList = () => {
    
    const dispatch = useDispatch();
    const authorsState = useSelector(selectors.authorsState);
    const authors = authorsState.data ? authorsState.data : [];

    useEffect(() => {
        dispatch(fetchAuthorList());
    }, [dispatch]);

    return (
        <div>
            <ol className="author_list">
            { [1,2,3,4,5,6,7,8,9,0] && [1,2,3,4,5,6,7,8,9,0].map((author, index) => (
                <li key={index}>
                    <Charities user={author} />
                </li>
            ))}
            </ol>
        </div>
    );
};
export default memo(CharityList);