import React from "react";
import Guest from "../../assets/Images/blank-profile.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useEffect } from "react";
import {
  galleryAction,
  galleryUploadAction,
} from "../../store/actions/thunks/auth";
import { useDispatch, useSelector } from "react-redux";

function GalleryImages() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [file, setFile] = useState("");
  const [base64NftIMg, setBase64NftIMg] = useState("");
  const [data, setData] = useState({
    text: "",
    file: "",
    userid: "",
  });

  const signedInUser = useSelector((e) => e.auth.signedInUser);
  const galleryData = useSelector((e) => e.auth.galleryData);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(galleryAction(signedInUser._id));
  }, [signedInUser]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    if (file) {
      (async () => {
        // const file = document.querySelector("#myfile").files[0];
        const img = await toBase64(file);
        setBase64NftIMg(img);
        console.log(img, "img");
      })();
    }
  }, [file]);
  const clearData = () => {
    setData({
      text: "",
      file: "",
    userid: "",
  });
    handleClose();
  };
  const saveData = () => {
    dispatch(galleryUploadAction(data, clearData));
  };

  useEffect(()=>{
    console.log(galleryData,'galleryData')
  },[galleryData])

  return (
    <div className="GalleryImages">
      <div className="innerGalleryImages">
        <div className="imageGalleryDiv" onClick={handleShow}>
          <img src={"./img/collections/coll-item-3.jpg"} />
          <p>Click here to upload</p>
        </div>
        {galleryData && galleryData.map((a, i) => (
          <div className="imageGalleryDiv">
            <img src={a.portraitimages} />
            <p>{a.portraittext}</p>
          </div>
        ))}
      </div>
      <Modal show={show} onHide={handleClose} className="uploadGalleryModal">
        <Modal.Body>
          <h3>Upload Image</h3>
          <div className="uplaodImageGallery">
            <label className="uplaodImageGalleryLabel">
              <input
                type="file"
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.file = ev.target.files[0];
                  obj.userid = signedInUser._id
                  setFile(ev.target.files[0]);
                  setData(obj);
                }}
              />
              {base64NftIMg ? (
                <img className="uploadedImage" src={base64NftIMg} />
              ) : (
                <div className="uploadText">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
                    ></path>
                  </svg>
                  <p>Upload</p>
                </div>
              )}
            </label>
            <label className="uplaodImageGalleryTextLabel">
              <p>Write something</p>
              <input
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.userid = signedInUser._id
                  obj.text = ev.target.value;
                  setData(obj);
                }}
              />
            </label>
          </div>
          <div className="uploadButtons">
            <button className="saveBtn btn-main  " onClick={saveData}>
              Save
            </button>
            <button className="closeBtn" onClick={handleClose}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GalleryImages;
