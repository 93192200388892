import React, { memo, useEffect, useState } from "react";
// import NftMusicCard from "./NftMusicCard";
import { useSelector, useDispatch } from "react-redux";
// import * as selectors from "../../store/selectors";
// import * as actions from "../../store/actions/thunks";
// import { clearNfts, clearFilter } from "../../store/actions";
// import { shuffleArray } from "../../store/utils";
import { onSellNft } from "../../store/actions/thunks/NFT";
import NftCard from "./NftCard";
import Spinner from "../Pages/Spinner";

const ColumnWithoutTime = ({
  pages,
  setpages,
  nfts,
  setNfts,
  showLoadMore = true,
  shuffle = false,
  authorId = null,
}) => {
  const dispatch = useDispatch();
  // const [pages, setpages] = useState(0);
  //   const nftItems = useSelector(selectors.nftItems);
  const [height, setHeight] = useState(0);

  const getNft = useSelector((e) => e.NFTData.onSellData);
  const sellLoader = useSelector((e) => e.NFTData.onSellLoader);
  const sellLength = useSelector((e) => e.NFTData.onSellLength);

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  const loadMore = () => {
    let page = pages;
    setpages(pages + 16);
    dispatch(onSellNft(page + 16, 16));
  };
  useEffect(() => {
    setNfts(nfts?.length ? [...nfts, ...getNft] : getNft);
  }, [getNft]);
  return (
    <div className="row">
      {/* {nfts.length > 1 && nfts.slice(0,10).map((nft, index) => { */}
      {nfts && nfts.length ? (
        <>
          {nfts?.map((nft, index) => {
            return (
              <NftCard
                // nft={nft}
                // audioUrl={nft.audio_url}
                // key={index}
                // onImgLoad={onImgLoad}
                // height={height}
                nft={nft}
                key={index}
                onImgLoad={onImgLoad}
                height={height}
              />
            );
          })}
          {!sellLoader && pages + 16 <= sellLength && (
            <div className="col-lg-12">
              <div className="spacer-single"></div>
              <span onClick={loadMore} className="btn-main lead m-auto">
                Load More
              </span>
            </div>
          )}
        </>
      ) : (
        !sellLoader && (
          <div className="nothingSell">
            <p>There is nothing on sell</p>
          </div>
        )
      )}
      {sellLoader ? (
        <div className="Spinner-Parent">
          <Spinner />
        </div>
      ) : null}
    </div>
  );
};

export default memo(ColumnWithoutTime);
