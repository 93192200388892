import { Modal } from "react-bootstrap";
import xummIcon from "./../../../assets/Images/xumm.png";
import walletConnectPng from "./../../../assets/Images/walletConnectPng.png";
import xrplWallet from "./../../../assets/Images/xrplWallet.png";
import { useEffect, useState } from "react";
import {
  connectXum,
  connectXumQr,
  disconnectWallet, signedIn,
  wallet_connect,
  connectXummLoader
} from "../../../store/actions/thunks/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../constant";
import QrCodeModal from "../../components/QrCodeModal";
const { io } = require("socket.io-client");

function XummWalletModal({
  show,
  setShow,
  handleClose,
  handleShow,
  connectButonHide,
  disConnectButtonHide,
  navButton,
  ScanConnectWallet,
  setActiveTab,
  userData,
  XummModalShow,
  ConnectWalletShow,
  setXummModalShow,
  setConnectWalletShow,
  ConnectModalHide,
}) {
  // const [cumImg, setCumImg] = useState(false);
  const [socketId, setSocketId] = useState("");
  const [accountAddress, setAccountAddress] = useState("");
  const [walletAccountAddress, setWalletAccountAddress] = useState("");
  // const [balance, setBalance] = useState("");
  // const [result, setResult] = useState("");
  const [QRCodeScanLoader, setQRCodeScanLoader] = useState(false);
  const [QrModalShow, setQrModalShow] = useState(false);
  const connectXumm = useSelector((e) => e.auth.connectXumm);
  const connectXummLoaderBool = useSelector((e) => e.auth.connectXummLoader);
  const connectXummLink = useSelector((e) => e.auth.connectXummLink);
  const signedInUser = useSelector((e) => e.auth.signedInUser);

  let dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    if (show) {
      navigate("/Connect-Wallet");
    }
  }, [show]);
  useEffect(() => {
    if (ConnectWalletShow && ConnectModalHide) {
      walletConnectFunc();
    }
  }, [ConnectWalletShow]);
  useEffect(() => {
    if (ConnectModalHide) {
      setXummModalShow(QrModalShow);
    }
  }, [QrModalShow]);
  useEffect(() => {
    if (XummModalShow && ConnectModalHide) {
      dispatch(connectXum(socketId));
      setQrModalShow(XummModalShow);
    }
  }, [XummModalShow]);
  useEffect(() => {
    if (show) {
      dispatch(connectXummLoader(false));
      dispatch(connectXumQr(false));
      console.log(show, "show");
    } else if (!show) {
      dispatch(connectXummLoader(false));
    }
  }, [show]);

  useEffect(() => {
    console.log(connectXumm, "connectXumm");
  }, [connectXumm]);
  useEffect(() => {
    console.log(connectButonHide, "connectButonHide");
  }, [connectButonHide]);
  const connectWallet = () => {
    setQrModalShow(true);
    if (ScanConnectWallet) {
      dispatch(connectXum(socketId, userData));
    } else {
      dispatch(connectXum(socketId));
    }
  };
  const walletConnectFunc = () => {
    dispatch(wallet_connect(socketId));
  };

  useEffect(() => {
    // const socket = io(baseURL);
    const socket = io(BASE_URL);

    socket.on("connect", function (data) {
      console.log("connected", socket.id);
      setSocketId(socket.id);
      console.log(socket.id);
    });
    socket.on("liveXum", (data) => {
      console.log(data, "runnnnnnnnnn");
      if (data?.data?.opened) {
        setQRCodeScanLoader(true);
      } else if (typeof data?.data === "string") {
        toast.error(data?.data);
        setQRCodeScanLoader(false);
        connectWallet();
        setQrModalShow(false);
      }
      // else if (data?.data?.signed === false) {
      //   setQRCodeScanLoader(false);
      // }
      //   console.log(data,'');
    });
    socket.on("result", (data) => {
      // setResult(data);
      console.log(data);
      setAccountAddress(data?.result?.response?.account);
      if (data?.type === "error") {
        toast.error(data?.message);
        setQRCodeScanLoader(false);
        setQrModalShow(false);
        connectWallet();
      } else if (typeof data?.nft === "string") {
        toast.error(data?.nft);
        setQRCodeScanLoader(false);
        setQrModalShow(false);
        connectWallet();
      } else if (data?.result?.response?.account) {
        localStorage.setItem("walletAddress", data.result.response.account);
        localStorage.setItem("nexibleToken", data.token);
        setQRCodeScanLoader(false);
        localStorage.setItem("nexibleUser", JSON.stringify(data.user));
        dispatch(signedIn());
        if (!data.user.email) {
          navigate("/add-Info");
        }
        if (data?.user?.xumm_scan && ScanConnectWallet) {
          setActiveTab("Pricing");
        }
        // handleClose();
        setQrModalShow(false);
        toast.success("Wallet connected successfully");
        console.log(data, "runnnnnnnnnn");
      }
      // token dbe22deb-bc6c-4e35-ac0a-fe9cd3305a04
      // account ry8ydKHUJP4vna4V7kuAGmDAX9Xzd4oxf
    });

    socket.on("disconnect", function (message) {
      console.log("Socket disconnected from server: ", message);
    });

    return () => {
      socket.close();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(connectXummLoaderBool, "connectXummLoaderBool");
  }, [connectXummLoaderBool]);

  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   if (
  //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //       navigator.userAgent
  //     )
  //   ) {
  //     setIsMobile(true);
  //   }
  // }, []);

  return (
    <>
      {navButton ? (
        <>
          {connectButonHide ? (
            <button className="connectButtonDrawer" onClick={handleShow}>
              Connect
            </button>
          ) : (
            <button
              className="connectButtonDrawer"
              onClick={() => {
                dispatch(disconnectWallet(navigate, setAccountAddress));
                setAccountAddress("");
              }}
            >
              Disconnect
            </button>
          )}
        </>
      ) : (
        <>
          {disConnectButtonHide ||
          ConnectModalHide ? null : ScanConnectWallet ? (
            <div className="connect-wal" onClick={handleShow}>
              {/* <a>disconnect</a> */}
              <a>
                <strong>Connect Wallet</strong>
              </a>
            </div>
          ) : signedInUser?.connect_wallet ? (
            <div
              className="connect-wal"
              onClick={() => {
                dispatch(disconnectWallet(navigate, setAccountAddress));
              }}
            >
              <a>Disconnect</a>
              {/* <a>Connect Wallet</a> */}
            </div>
          ) : !signedInUser?.xumm_scan && signedInUser ? (
            <div
              className="connect-wal"
              onClick={() => {
                dispatch(disconnectWallet(navigate, setAccountAddress));
              }}
            >
              <a>Sign out</a>
              {/* <a>Connect Wallet</a> */}
            </div>
          ) : connectButonHide ? (
            <>
              {accountAddress ? (
                <div
                  className="connect-wal"
                  onClick={() => {
                    setAccountAddress("");
                  }}
                >
                  <a>Disconnect</a>
                  {/* <a>Connect Wallet</a> */}
                </div>
              ) : (
                <div className="connect-wal" onClick={handleShow}>
                  {/* <a>disconnect</a> */}
                  <a>Connect Wallet</a>
                </div>
              )}
            </>
          ) : disConnectButtonHide ? null : (
            <div
              className="connect-wal"
              onClick={() => {
                dispatch(disconnectWallet(navigate, setAccountAddress));
              }}
            >
              <a>Disconnect</a>
              {/* <a>Connect Wallet</a> */}
            </div>
          )}
        </>
      )}

      <Modal
        show={false}
        // show={show}
        // onHide={handleClose}
        style={{ minHeight: "182px" }}
        className="connectWalletModal"
      >
        <Modal.Body>
          <div className={`innerConnectModal`}>
            {accountAddress || walletAccountAddress ? (
              <h4 className="connectWalletHeading">Wallet Is Connected</h4>
            ) : (
              // : connectXummLoaderBool || QRCodeScanLoader ? (
              //   <>
              //     <div className="spinner-borderDiv">
              //       <div className="spinner-border " role="status">
              //         <span className="sr-only"></span>
              //       </div>
              //     </div>
              //   </>
              // ) : (
              //  connectXumm ? (
              //   <>
              //     <div className="qrCodeDiv">
              //       <img src={connectXumm} alt="png" />
              //       <h4 className="connectWalletHeading">
              //         Scan QR Code From your Xumm App
              //       </h4>
              //     </div>
              //     {isMobile ? (
              //       <div className="removeUnderlineDiv">
              //         <a
              //           href={connectXummLink}
              //           target="_blank"
              //           className="removeUnderline"
              //         >
              //           XUMM Wallet
              //         </a>
              //       </div>
              //     ) : null}
              //   </>
              // ) :
              <>
                {/* {!connectXumm && !connectXummLoaderBool ? ( */}
                {/* <> */}
                <h4 className="connectWalletHeading">Connect Wallet</h4>
                <div className="wallet" onClick={connectWallet}>
                  <img src={xummIcon} />
                  <div>
                    <h6>Connect Your Xumm Wallet</h6>
                    <p>Click here to connect your XUMM Wallet</p>
                  </div>
                </div>
                {/* </> */}
                {/* ) : null} */}

                <div className="wallet" onClick={walletConnectFunc}>
                  <img src={walletConnectPng} alt="walletConnect" />
                  <div>
                    <h6>Connect Your WalletConnect</h6>
                    <p>Click here to connect your walletConnect</p>
                  </div>
                </div>
                <div
                  className="wallet createButton"
                  onClick={() => {
                    // handleClose();
                    navigate("/add-Info");
                  }}
                >
                  <img src={xrplWallet} alt="walletConnect" />
                  <div>
                    <h6>Create Your Wallet</h6>
                    <p>Click here to create your wallet</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <QrCodeModal
        show={QrModalShow}
        setShow={setQrModalShow}
        XummData={{
          connectXummLink: connectXummLink,
          connectXumm: connectXumm,
        }}
        Text={"Scan QR Code From your Xumm App"}
        Loader={connectXummLoaderBool || QRCodeScanLoader}
      />
    </>
  );
}

export default XummWalletModal;
