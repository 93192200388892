import {
  FILTEREDNFTDATA,
  FILTEREDNFTDATALENGTH,
  FILTEREDNFTLOADER,
  FILTEREDNFTSEARCH,
  FILTEREDNFTSEARCHTAGS,
} from "../actions/thunks/filters";

export const defaultState = {
  FilteredNftData: false,
  FilteredNftLoader: false,
  FilteredNftDataLength: false,
  FilteredNftSearch: false,
  FilteredNftSearchTags: "",
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    case FILTEREDNFTDATA:
      // console.log(action)
      return {
        ...state,
        FilteredNftData: action.payload,
      };
    case FILTEREDNFTLOADER:
      // console.log(action)
      return {
        ...state,
        FilteredNftLoader: action.payload,
      };
    case FILTEREDNFTSEARCHTAGS:
      // console.log(action)
      return {
        ...state,
        FilteredNftSearchTags: action.payload,
      };
    case FILTEREDNFTDATALENGTH:
      // console.log(action)
      return {
        ...state,
        FilteredNftDataLength: action.payload,
      };
    case FILTEREDNFTSEARCH:
      // console.log(action)
      return {
        ...state,
        FilteredNftSearch: action.payload,
      };
    default:
      return state;
  }
};
export default states;
