import React from "react";
import { Modal } from "react-bootstrap";
import Image from "../../assets/Images/QR_Code_Example.png";
export default function QrCodeModal({ XummData, show, Text, setShow, Loader }) {
  const handleClose = () => {
    if (!Loader) {
      setShow(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose} className="QrCodeModal">
      <Modal.Body>
        <div className="inner-QrCodeModal">
          <h4 className="connectWalletHeading">{Text}</h4>
          <div className="connectXumm">
            <div className={`overLay ${Loader}`}>
              <div className="spinner-borderDiv">
                <div className="spinner-border " role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </div>
            {/* <img src={Image} className={`Loader ${Loader}`} alt="png" /> */}
            <img
              src={Loader ? Image : XummData?.connectXumm || Image}
              alt="png"
            />
          </div>
          <div className="removeUnderlineDiv">
            <a
              href={XummData?.connectXummLink}
              target="_blank"
              className="removeUnderline"
            >
              XUMM Wallet
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
