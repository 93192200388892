import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { createAucNft, removeAucNft } from '../../store/actions/thunks/NFT';
import { DatePicker, InputNumber } from 'antd';
const { RangePicker } = DatePicker;

function AddAucModal({
    show,
    handleClose,
    nftObj,
    socketId,
    nftId,
    singleNft,
    editId
}) {
    const dispatch = useDispatch()
    // const user = useSelector((e) => e.auth.signedInUser);
    // const bidQRCode = useSelector((e) => e.NFTData.bidQRCode);
    const addAucLoader = useSelector((e) => e.NFTData.addAucLoader);
    const remAucLoader = useSelector((e) => e.NFTData.remAucLoader);

    // const dateFormat = 'YYYY/MM/DD';
    const [obj, setObj] = useState({
        "nftId": "",
        "initialBidAmount": "",
        "startDate": new Date(),
        "closeDate": new Date().setDate(new Date().getDate() + 1)
    })

    function addToAuc() {
        let data = {
            ...obj,
            nftId: nftId,
        }
        if (editId) {
            dispatch(createAucNft(data, handleClose, 'put'))
        } else {
            dispatch(createAucNft(data, handleClose, 'post'))
        }
        // console.log(data)

    }

    function removeAuc() {
        dispatch(removeAucNft(nftId, handleClose))

    }

    useEffect(() => {
        if (editId) {
            setObj({
                "nftId": editId,
                "initialBidAmount": singleNft.initialBidAmount,
                "startDate": new Date(singleNft.startDate),
                "closeDate": new Date(singleNft.closeDate)
            })
        } else {
            setObj({
                "nftId": "",
                "initialBidAmount": "",
                "startDate": "",
                "closeDate": ""
            })
        }
    }, [editId])

    const dateFormatFn = (date) => {
        
    }


    useEffect(()=>{
        dateFormatFn(new Date())
    },[])

    return (
        <Modal show={show} onHide={handleClose} className="bidModal">
            <Modal.Body>
                <button
                    className="btn-close"
                    onClick={() => handleClose()}
                >
                    x
                </button>
                <div className="heading">
                    <h3>NFT Auction</h3>
                </div>
                <p>
                    You are about to place your NFT on Auction
                </p>

                <>
                    <div className='heading bidAmmountField'>
                        <p>
                            Starting Amount
                        </p>
                        <InputNumber type='number' value={obj.initialBidAmount} onChange={(ev) => {
                            let objj = { ...obj }
                            console.log(ev)
                            if (ev > 0) {
                                objj.initialBidAmount = String(ev)
                            } else {
                                objj.initialBidAmount = 0
                            }
                            setObj(objj)
                        }} />
                        {/* <div className="subtotal">{nftObj?.amount / 1000000} XRP</div> */}
                    </div>
                    <div className='heading duration'>
                        <p>
                            Duration
                        </p>
                        <RangePicker showTime
                            // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/15/01', dateFormat)]}
                            // value={[moment(obj.startDate, dateFormat), moment(obj.closeDate, dateFormat)]}
                            className='durationDatePicker' popupStyle={{
                                zIndex: 999999
                            }}
                            onChange={(ev) => {
                                let dates = ev
                                let objj = { ...obj }
                                objj.startDate = dates[0]._d
                                objj.closeDate = dates[1]._d
                                setObj(objj)
                                // console.log(ev)
                            }}
                        />
                        {/* <div className="subtotal">{nftObj?.amount / 1000000} XRP</div> */}
                    </div>
                    <button
                        className="btn-main lead "
                        onClick={addToAuc}
                        disabled={false}
                    >
                        {addAucLoader ? (
                            <div className="spinner-borderDiv">
                                <div className="spinner-border " role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        ) : (
                            `Place Now`
                        )}
                    </button>
                    {editId ?
                        <button
                            className="btn-main lead mb-5 removeAuc"
                            onClick={removeAuc}
                            disabled={false}
                        >
                            {remAucLoader ? (
                                <div className="spinner-borderDiv">
                                    <div className="spinner-border " role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            ) : (
                                `Remove from Auction`
                            )}
                        </button>
                        : null}
                </>
            </Modal.Body>
        </Modal>
    )
}

export default AddAucModal