import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import BackImg from "../../assets/Images/Bannerconnect.jpg";
import logo from "../../assets/Images/Nexible.png";
import xummIcon from "../../assets/Images/xumm.png";
import walletConnectPng from "../../assets/Images/walletConnectPng.png";
import xrplWallet from "../../assets/Images/xrplWallet.png";
import ConnectBanner from "../../assets/Images/ConnectBanner1.jpg";
import ConnectBanner2 from "../../assets/Images/ConnectBanner2.jpg";
import ConnectBanner3 from "../../assets/Images/ConnectBanner3.jpg";
import ConnectBanner4 from "../../assets/Images/ConnectBanner4.jpg";
import ConnectBanner5 from "../../assets/Images/ConnectBanner5.jpg";
import ConnectBanner6 from "../../assets/Images/ConnectBanner6.jpg";
import { useState } from "react";
import XummWalletModal from "./Auth/XummWalletModal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
export default function ConnectWallet() {
  const signedInUser = useSelector((e) => e.auth.signedInUser);

  const navigate = useNavigate();
  const [XummModalShow, setXummModalShow] = useState(false);
  const [ConnectWalletShow, setConnectWalletShow] = useState(false);
  const [Image, setImage] = useState("");
  useEffect(() => {
    if (signedInUser) {
      navigate("/Home");
    }
  }, [signedInUser]);
  let arr = [
    ConnectBanner,
    ConnectBanner2,
    ConnectBanner3,
    ConnectBanner4,
    ConnectBanner5,
    ConnectBanner6,
  ];
  useEffect(() => {
    let index = 0;
    index = Math.floor(Math.random() * arr.length);
    setImage(arr[index]);
  }, []);
  return (
    <>
      <div className="connectWallet">
        <div className="connectWallet-parent">
          <div
            className="backGround"
            style={{ backgroundImage: `url('${Image}')` }}
          >
            <div className="Home">
              <Link to={"/Home"}>
                <img src={logo} />
              </Link>
            </div>
          </div>
        </div>
        <div className="connectWallet-parent-2">
          <div className="parent-2-child">
            <div className="ResponsiveImg">
              <img src={logo} style={{ width: "164px" }} />
            </div>
            <div className="connectHeading">Connect wallet</div>
            <div className="connectPara mb-4">
              Choose how you want to connect. There are several wallet
              providers.
            </div>
            <div className="Parent-Connect">
              <p className="connectPara">Popular</p>
              <div className="card-Parent">
                <div
                  className="connect-Card"
                  onClick={() => setXummModalShow(true)}
                >
                  <img src={xummIcon} />
                  <span>Connect Your Xumm Wallet</span>
                </div>
                <div
                  className="connect-Card"
                  onClick={() => setConnectWalletShow(true)}
                >
                  <img src={walletConnectPng} />
                  <span>Connect Your WalletConnect</span>
                </div>
                <Link to={"/add-Info"} className="connect-Card">
                  <img src={xrplWallet} />
                  <span>Create Your Wallet</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <XummWalletModal
        show={false}
        ConnectModalHide={true}
        XummModalShow={XummModalShow}
        ConnectWalletShow={ConnectWalletShow}
        setConnectWalletShow={setConnectWalletShow}
        setXummModalShow={setXummModalShow}
        connectButonHide={false}
        // setShow={setShow}
        // handleClose={handleClose}
        // handleShow={handleShow}
        // connectButonHide={false}
        // disConnectButtonHide={true}
      />
    </>
  );
}
