import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation, useNavigate
} from "react-router-dom";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Header from "./menu/header";
import Home from "./Pages/home";
import Explore from "./Pages/Explore/explore";
import HelpCenter from "./Pages/HelpCenter";
import Collection from "./Pages/Explore/collection";
import NFTDetail from "./Pages/Explore/NFTDetail";
import Author from "./Pages/Auth/Author";
import Wallet from "./Pages/Auth/wallet";
import Register from "./Pages/Auth/register";
import CreateSingleNFT from "./Pages/Create/CreateSingleNFT";
import CreateMultipleNFT from "./Pages/Create/CreateMultipleNFT";
import CreateOption from "./Pages/Create/index";
import Profile from "./Pages/Auth/Profile";
import auth from "../core/auth";
import "./../assets/style.scss";
import { createGlobalStyle } from "styled-components";
import Logintwo from "./pages Default/loginTwo";
import { connectSocket, getAccountId, signedIn } from "../store/actions/thunks/auth";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style.css";
import "antd/dist/antd.css";
import SingleBlog from "./Pages/SingleBlog";
import ConnectWallet from "./Pages/connectWallet";
import Blogs from "./Pages/Blogs";
import CreateWallet from "./Pages/Auth/CreateWallet";
import { io } from "socket.io-client";
import { BASE_URL } from "../constant";
import Contact from './pages Default/contact'
const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

// const ProtectedRoute = ({ children }) => {
//   let location = useLocation();
//   const isAuth = auth.getToken() !== null;

//   return isAuth ? (
//     children
//   ) : (
//     <Navigate to="/login" state={{ from: location }} replace />
//   );
// };

const AppInner = () => {
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const signedInUser = useSelector((e) => e.auth.signedInUser);
  const [socketId,setSocketId] = useState(false)
  const [socketState,setSocketState] = useState()
  let dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!signedInUser?.wallet_address) {
  //     navigate("/home");
  //   }
  // }, []);
  useEffect(() => {
    console.log(location.pathname);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location]);

  useEffect(() => {
    dispatch(signedIn());
    dispatch(getAccountId());
  // const socket = io(BASE_URL);
  //   dispatch(connectSocket(socket))
  const socket = io(BASE_URL);
  setSocketState(socket)
  socket.on("connect", function (data) {
    console.log("connected", socket.id);
    setSocketId(socket.id);
    // console.log(socket.id);
  });
  }, []);
  useEffect(() => {
    console.log(signedInUser);
  }, [signedInUser]);

  return (
    <>
      <div
        className="preloader"
        style={{ display: `${loading ? "block" : "none"}` }}
      >
        <div className="preloader-inner">
          <div className="preloader-icon">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className="wraper">
        <GlobalStyles />
        {window.location.pathname !== "/Connect-Wallet" && <Header socketId={socketId} socket={socketState} />}
        <ToastContainer
          style={{
            zIndex: "9999999",
          }}
        />
        <Routes>
          <Route path="*" element={<Navigate to="/Home" replace />} />
          <Route element={<Home socketId={socketId} socket={socketState} />} path="/Home" />
          <Route path="/Author">
            <Route
              path=":authorId"
              element={
                // <ProtectedRoute>
                <Author />
                // </ProtectedRoute>
              }
            />
          </Route>
          
          <Route element={<Contact socketId={socketId} socket={socketState} />} path="/Contact" />
          <Route element={<Profile socketId={socketId} socket={socketState} />} path="/Profile" />
          <Route element={<Explore socketId={socketId} socket={socketState} />} path="/Explore" />
          <Route element={<HelpCenter socketId={socketId} socket={socketState} />} path="/HelpCenter" />
          <Route element={<Collection socketId={socketId} socket={socketState} />} path="/User" />
          <Route element={<NFTDetail socketId={socketId} socket={socketState} />} path="/ItemDetail" />
          <Route element={<Wallet />} socketId={socketId} socket={socketState} path="/wallet" />
          <Route element={<Blogs />} socketId={socketId} socket={socketState} path="/Blogs" />
          <Route element={<SingleBlog socketId={socketId} socket={socketState} />} path="/Blog-Details" />
          <Route element={<ConnectWallet socketId={socketId} socket={socketState} />} path="/Connect-Wallet" />
          {/* <Route element={<Login />} socketId={socketId} socket={socketState} path="/login" /> */}
          <Route element={<Register socketId={socketId} socket={socketState} />} path="/register" />
          <Route element={<CreateOption socketId={socketId} socket={socketState} />} path="/CreateOptions" />
          <Route element={<CreateSingleNFT socketId={socketId} socket={socketState} />} path="/CreateSingleNFT" />
          <Route element={<CreateMultipleNFT socketId={socketId} socket={socketState} />} path="/CreateMultipleNFT" />
          <Route element={<Logintwo socketId={socketId} socket={socketState} />} path="/Login" />
          <Route element={<CreateWallet socketId={socketId} socket={socketState} />} path="/add-Info" />

          {/* <Route element={<XummExample />} path="/XummExample" /> */}
        </Routes>
        <ScrollToTopBtn />
        {/* <div className="preloader" >
      <div className="preloader-inner">
        <div className="preloader-icon">
          <span></span>
          <span></span>
        </div>
      </div>
    </div> */}
      </div>
    </>
  );
};
export default AppInner;
