import React, { memo } from 'react';
import api from '../../core/api';

//react functional component
const Charities = () => {
    return (
        <>
            <div className="author_list_pp">
              <span onClick={()=> window.open("", "_self")}>
                  <img className="lazy" src='https://thumbs.dreamstime.com/b/charity-word-cloud-heart-concept-56405290.jpg' alt=""/>
                  {/* <i className="fa fa-check"></i> */}
              </span>
            </div>                                    
            <div className="author_list_info">
                <span onClick={()=> window.open("", "_self")} className="author__name">Charity</span>
                {/* <span className="bot">{user.author_sale.sales} ETH</span> */}
            </div>   
        </>     
    );
};

export default memo(Charities);